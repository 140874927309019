@import "styles/index.scss";
.heading {
    &--centered {
        text-align: center;
    }
    &--h1 {
        font-size: 1.125rem;
        font-weight: normal;
        margin: 0 auto 40px;
    }

    &--h2 {
        margin: 0 auto 20px;
    }

    &--h4 {
        font-weight: 500;
        font-size: 16px;
    }
}


@media screen and (min-width: 40.625em) {
    .heading--h1 {
        font-size: 1.625rem;
      }
    }
