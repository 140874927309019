@import 'styles/index';

.checklist-accordion-header-container {
    padding: 10px 20px;
    .checklist-accordion-header-toggle {
        width: 100%;

        .checklist-accordion-header {
            color: get-color('aqua');
            display: block;
            font-family: $proxima;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: (19/16);
            padding-left: 12px;
            text-decoration: none !important;
            position: relative;

            .checklist-header-text {
                margin-bottom: 0px;
                &::after {
                    border: solid transparent;
                    border-color: rgba(136, 183, 213, 0);
                    border-top-color: get-color('aqua');
                    border-width: 6px;
                    content: ' ';
                    display: block;
                    height: 0;
                    left: 0%;
                    margin-left: -6px;
                    pointer-events: none;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-40%) scaleX(0.8);
                    width: 0;
                    transition: transform .3s;
                  }
            }

            &:hover,&:focus,&:active {
              text-decoration: none;
            }

            &--open {
                .checklist-header-text {
                    &::after {
                        transform: translateY(-80%) scale(0.8, -1);
                    }
                }
            }
        }
    }

    &.with-add {
        display: flex;
        .checklist-accordion-header-toggle {
            width: 60%;
        }

        .add-task-button {
            text-align: right;
            width: 40%;
            margin-bottom: 0;
            color: get-color('aqua');
            padding-top: 5px;
            border-bottom: 1px solid get-color(mystic);
        }
    }

    @media (max-width: 764px) {
        .checklist-accordion-header-toggle {
            margin: 0;
        }
        &.with-add {
            .checklist-accordion-header-toggle {
                width: 100%;
            }
        }
    }
}