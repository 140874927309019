@import 'styles/index';

.text-link {
    font-family: $proxima;
    font-weight: 600;
    text-decoration: none;
    color: get-color('boston-blue');
    &:active,
    &:visited {
        color: get-color('boston-blue');
    }
    &:hover {
        color: get-color(casal);
        text-decoration: underline;
    }
}
