@import 'styles/index';

.invite-page-container {
  background-color: get-color('white');
  border: 1px solid get-color(mystic);
  border-radius: 4px;
  padding: 2.5rem;

  .invite-page-content {
    width: 56%;
    margin: 60px auto;
  }

  .username-message {
      margin: 25px 0px;
  }

  .policy-links {
    width: 100%;
    text-align: center;
    margin: auto;
  }

  @media (max-width: 764px) {
    .invite-page-content {
        width: 100%;
      }
  }

}
