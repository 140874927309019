.hamburger-menu {
  background: white;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  bottom: 0;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.15);
  left: 0;
  padding: 50px 50px 85px;
  pointer-events: none;
  position: fixed;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out, opacity .2s linear;
  width: 100%;
  z-index: 99;
  opacity: 0;

  &--open {
    pointer-events: auto;
    transform: none;
    opacity: 1;
  }

  &__list {
      list-style: none;
      margin: 0;
      padding: 0;
  }

  &__list-item {
      margin-bottom: 45px;
  }
  &__list-item-link {
      padding: 0;
      background: transparent;
      border:0;
  }
}


.hamburger-menu-link {
    font-weight: 600;
    color: black;
    display: flex;
    align-items: center;

    &__icon {
        margin-right: 6px;
    }

    &:active,&:focus,&:hover {
      text-decoration: none;
      color:black;

    }
}


@media screen and (min-width:768px) {
  .hamburger-menu {
    display: none;
  }
}
