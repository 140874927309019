@import 'styles/index.scss';

.mi-user-badge-container {
  background-clip: padding-box;
  background-color: transparent;
  border-radius: 100%;
  height: 50px;
  overflow: hidden;
  padding: 4px;
  width: 50px;

  &:active {
    outline: 0;
  }

  .icon {
    display: block;
    margin:0 auto;
    height: 90%;
  }

  &.clickable:focus {
    outline: none;
  }

  .header-user-image {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  &.light {
    border: 1px solid get-color('white');
  }

  &.dark {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  &.no-border {
    border:0;

  }

  &.clickable:hover,
  &.active {
    background-color: rgba(255, 255, 255, 0.15);
  }
  &.extra-small {
    height: 30px;
    margin-top: 11px;
    padding: 0;
    width: 30px;
    border-radius: 15px;
    overflow: hidden;
  }


  &.small {
    height: 35px;
    margin-top: 8px;
    padding: 3px;
    width: 35px;
  }

  &.medium {
    height: 42px;
    padding: 3px;
    width: 42px;
  }

  &.large {
      height: 46px;
      width: 46px;;

      .header-user-image {
        height: 46px;
      width: 46px;;
      }
  }

  .header-user-image {
    height: 34px;
    width: 34px;

  }
}
