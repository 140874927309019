@import 'styles/index';

.personal-task-controls {
    .button {
        font-size: 14px;
        width: 20%;

        .icon {
            padding-right: 5px;
        }

        &.disabled {
            color: get-color('body-text')
        }
    }

    @media (max-width: 764px) {
        .button {
            width: 80px;
        }
    }
}