@import 'helpers';
@import 'colors';
@import 'get-color';
@import 'helpers/media-query';
@import 'helpers/html-styles';
@import 'variables';

.main--container {
  background: get-color('portal-bg-color');
  min-height: calc(100vh - 60px);
  padding: 2.5rem 0;
}

.main--no-vertical-padding-mobile,
.main--no-vertical-padding {
  padding: 0;
}

.main--has-bottom-docked-nav-on-mobile {
  background: get-color('portal-bg-color');
  min-height: 100vh;
  padding-bottom: 85px;
  overflow: hidden;
}

@media screen and (min-width: 37.5rem) {
  .main--container {
    padding: 2.5rem 0;
  }
  .main--no-vertical-padding {
    padding: 0;
  }
  .main--no-vertical-padding-mobile {
    padding: 2.5rem 0;
  }
}
@include media-query(764) {
  .main--has-bottom-docked-nav-on-mobile {
    padding-bottom: 0;
  }
}

