@import 'styles/index';
.no-modal-support {
  .checklist {
    opacity: 0;
    transition: opacity .250s ease-out;
    &[open] {
      pointer-events: auto;
      opacity: 1;
    }
  }
}
.modal-close {
  position: absolute;
  top: 26px;
  left: 30px;
  background-color: transparent;
  padding: 0;
  border: 1px solid get-color(silver-chalice);
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &:hover {
    background-color: get-color(alto);
  }
  &:active,
  &:focus {
    outline-color: get-color(loblolly);
  }

  &__inner {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;

    text-indent: -999em;
    &:after,&:before {
      width: 2px;
      content: ' ';
      border-radius: 1px;
      background-color: get-color('black');
      height: 55%;
      position: absolute;
      top:9px;
      left:50%;
    }
    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
  }
}

.checklist {

  .accordion {
    margin-top: 25px;
    &.open {
      .personal-checklist-toggle-accordion-icon, .mi-checklist-toggle-accordion-icon {
          transform: rotate(180deg);
      }
    }
  }
  background-color: get-color('white');
  border: 0;
  border-radius: $global-border-radius;

  left: 50vw;
  margin: 0;
  max-height: calc(100vh - 40px);
  max-width: 750px;
  overflow: auto;
  padding: 40px 30px;
  position: fixed;
  top: 50vh;
  transform: translate(-50%, -50%);
  width: calc(100vw - 40px);
  z-index: 100;
  pointer-events: none;

  &::backdrop {
    opacity: 0;
    transition: opacity .35s ease-out;
  }

  &[open] {
    pointer-events: auto;
    &::backdrop {
      opacity: 1;
    }
  }

  &--page {
    border: 0;
    position: relative;
    margin:0;
    width: 100%;
    max-width: none;
    transform:none;
    top:auto;
    left:auto;
    height: auto;
    min-height:calc(100vh - 85px);
    max-height: none;
    z-index: 2;
    pointer-events: auto;
    padding:0 0 85px;
    border-radius:0;
  }

  &__title {
    color: get-color(oxford-blue);
    font-family: $proxima;
    font-size: 1.5rem;
    margin: 0 0 20px;
    position: relative;

    &--page {
      background-color: get-color(neutral-light);
      font-size: 1.25rem;
      font-weight: 400;
      padding: 20px;
      text-align: center;
      max-height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }


  @include media-query(764) {
    .checklist-modal-header {
      padding: 0px 70px;
    }

    &__title {
      font-family: $museo;
    }
  }

  &__body {
    color: get-color(tundora);
    font-family: $proxima;
    font-size: 1rem;
    line-height: (19/16);
    margin: 0 0 10px;

    &--last {
      margin-bottom: 40px;
      font-weight: 600;
    }

    &--page {
      margin: 0 auto 30px;
      max-width: calc(100% - 26px);
      &.checklist__body--last {
        margin-bottom: 30px;
      }
    }
  }

  .btn {
    border-radius: 0;
    text-align: left;
    text-decoration: none;
    border-bottom:1px solid get-color(neutral-medium);
    &:focus,&:active,&:hover {text-decoration: none;}
    margin:0 20px;

  }
}

.top-level-task-category-list {
  margin:0;
  padding:0;
  &.accordion {
    margin-top: 0;
  }

  &.hide {
    display: none;
  }
}

.single-task {
  transition: background-color .2s ease-out;
  font-family: $proxima;
  padding-left: 20px !important;

  &:hover {
    background-color: rgba(243, 245, 247, .5) !important;
  }
  &--expanded {
    background-color: get-color(wild-sand) !important;
  }

  .btn {
    border-bottom: 0;
  }
  &--modal {
    display: flex;
    border-radius: 3px !important;
    flex-wrap: wrap;
    position: relative;
    flex-direction: row !important;
    padding: 15px;
    font-size: .875rem !important;

      align-items: flex-start;
  }

  &__body {
    font-size: .875rem;
    font-weight: 600;
    width: 100%;
    padding-right: 10px;
  }

  &__due-date,
  &__body {
    color: get-color(dusty-grey);
  }
  &__body-text {
    margin-top: 10px;
    font-weight: 400;
    margin-bottom: 0;
  }

  &__due-date {
    color:get-color('black');
    opacity: .25;
    font-size: .75rem;
    margin-bottom: 0;
    -webkit-font-smoothing: subpixel-antialiased;

    &.overdue {
      color: get-color(err);
      opacity: 1;
    }

    &.duetoday {
      color: get-color(err);
      opacity: 1;
    }

    &.duesoon {
      color: get-color(tangerine);
      opacity: 1;
    }
  }

  &__occur__phase {
    color: get-color('black');
    opacity: .25;
    font-size: .75rem;
    margin-bottom: 0;

    &.system-completed {
      color: get-color(body-text);
      opacity: 1;
    }
  }

  &__title {
    font-size: .875rem !important;
    font-weight: 600;
    color: get-color(tundora);
    padding-right: 30px;
    max-width: 97%;
  }

  &__links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  &__link {
    color: get-color(boston-blue);
    margin-top: 20px;
    .icon {
      float: right;
      margin-left: 15px;
      transition: transform .2s ease;
      transform: translateY(-2px);
    }
    &:hover,&:focus,&:active {
      text-decoration: underline;
      color: get-color(boston-blue);
      .icon {
        transform: translate(5px, -2px);
      }
    }

    &.disabled {
      pointer-events: none;
      color: get-color('body-text');
    }


  }

  .icon--circle {
    margin-right: 0;
    svg{top:45%;}
  }
  &__icon {
    position: absolute;
    right:20px;
    top:10px;
    max-width: 28px;
    max-height: 28px;
    overflow: hidden;
    transform: none;


    &--expanded {

      transform: scaleY(-1);
    }
  }
  &__up-content {

    flex:0 0 calc(100% - 20px);
    display: block !important;
    padding-top:2px !important;
    padding-left: 8px !important;
    z-index: 1;
    padding-bottom: 0 !important;
    font-weight: 600 !important;
    margin-bottom: 0;
    line-height: (18/14) !important;
    color: get-color('black') !important;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin:0 !important;

    &:focus,&:active {
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
}
.checklist::-webkit-backdrop,
.checklist::backdrop {
  background-color: $modalbg;

  height: 100vh;
  pointer-events: none;
  position: fixed;
  width: 100vw;
  z-index: 99;
}
@include media-query(764) {

  .single-task {
    &--expanded {
      background-color: get-color(wild-sand) !important;
    }
  }
  .checklist--modal {
    overflow: hidden;
    padding: 0;
    .modal-close__inner {
      background-color: white;
      border-radius: 50%;
    }
    .modal-close {
      z-index: 2;
    }
  }
  .checklist__scroll-content {
    overflow: auto;
    max-height: 100%;
    max-height: calc(100vh - 40px);
    padding: 40px 30px 20px;
    z-index: 1;
  }

  .checklist .btn {
    margin:0;
  }
  .single-task__link {
    &:nth-child(2):last-child {
      margin-left: 50px;
      margin-right: auto;
    }
  }
  .single-task--modal {
    margin-top: 5px;
    padding: 15px 15px !important;
    margin: 10px 15px;
  }
  .single-task__body {
    padding-left: 25px;
  }
  .single-task__title {
    padding-right: 0;
  }
}
