@import 'styles/index';
@include media-query(764) {
  .fade-and-rise-appear {
    opacity: 0;
    transform: translateY(20%);
  }

  .fade-and-rise-appear-active {
    opacity: 1;
    transform: none;
    transition: opacity 250ms, transform 350ms;
  }

  .fade-and-rise-appear-done {
    opacity: 1;
    transform: none;
  }
}

.location-card {
  border: 1px solid get-color(mercury);
  border-radius: $global-border-radius;
  color: get-color(dusty-grey);
  padding: 20px;

  &--left-column {
    margin-left: 20px;
    margin-right: 20px;
  }

  p {
    margin: 0 0 3px;
  }

  &__link {
    display: block;

    &:hover,
    &:active,
    &:focus {
      .location-card__map-image {
        transform: scale(1.1);
      }
    }
  }

  a {
    color: get-color(silver-chalice);

    &:hover,
    &:active,
    &:focus {
      color: get-color('dark-aqua');
    }
  }

  &__address {
    margin: 0;

  }

  &__title {
    color: get-color(silver-chalice);
    font-size: 1rem;
    margin: 0 0 20px;
  }

  &__branch {
    color: get-color(tundora);
    font-size: 0.875rem;
    font-weight: 600;
  }

  &__map {
    background-color: get-color(neutral-medium);
    border-radius: $global-border-radius;
    height: 0;
    margin-bottom: 20px;
    object-fit: cover;
    object-position: center center;
    overflow: hidden;
    padding-bottom: 28.73%;

  }

  &__map-image {
    height: 100%;
    transition: transform  0.2s ease-out;
    width: 100%;
  }

  &__info {
    &-piece {
      color: get-color(dusty-gray);
      margin-top: 20px;
      font-size: 0.75rem;
      font-weight: 600;
      line-height: (15/12);
    }
  }
}

@include media-query(764) {
  .location-card {
    margin-bottom: 0;

    &--left-column {
      margin: 0 0 20px;
      display: flex;
      flex-wrap: wrap;
    }

    &__link--left {
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;

    }

    &__title--left {
      flex: 0 0 100%;
    }

    &__map--left {
      flex: 0 0 33.3%;
      height: auto;
      margin-bottom: 0;
      order: 2;
      padding-bottom: 0;
      position: relative;
    }
  }
  .location-card__map-image--left {
    object-fit: cover;
  }
  .location-card__info--left {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex:0 0 66.6%;
    order:1;
    padding-right: 10px;
  }
  .location-card__info-piece--left {
    flex: 0 0 calc(50% - 10px);
    margin-top: 0;
    width: calc(50% - 10px);

    &:only-child {
      width: 100%;
      flex: 0 0 100%;
    }
  }
}
