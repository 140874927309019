@import 'styles/index';
$base-size: 28px;

.icon {
  display: inline-block;
  min-height: 16px;

  svg {
    display: inline-block;
  }

  &--in-button {
    margin-right: 10px;
  }

  &--with-text {
    align-items: center;
    display: flex;;
    margin-left: 5px;
  }

  &--with-bg {
    align-items: center;
    border-radius: 7px;
    display: flex;
    height: 28px;
    justify-content: center;
    margin-right: 5px;
    width: 28px;
  }

  &--complete {
    &::after {
      background-color: get-color('white');
      height: 2px;
      width: 4px;
    }
  }

  &--border {
    border-style: solid;
    border-width: 1px;
  }
  &--tiny {
    height: 12px;
    width: 12px;
  }
  &--circle {
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    height: $base-size;
    margin: 0 12px 0 0;
    position: relative;
    text-indent: -999em;
    width: $base-size;
    z-index: 2;

    svg {
      height: auto;
      left: 50%;
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40%;
    }

    &.icon--small {
      height: ($base-size * 1.5);
      width: ($base-size * 1.5);
    }

    &.icon--medium {
      height: ($base-size * 2);
      width: ($base-size * 2);
    }

    &.icon--large {
      height: ($base-size * 2.5);
      width: ($base-size * 2.5);
      margin-right: 0;
    }

    &.icon--extralarge {
      height: ($base-size * 3);
      width: ($base-size * 3);
    }
  }

  &--centered {
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
      left: 55%;

    }
  }
}

