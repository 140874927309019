@import 'styles/index';

.mi-rep-card {
  &__name {
    color: get-color('indigo');
    font-size: 1rem;
  }

  &__title {
    font-size: 0.875rem;
    font-weight: 600;
  }

  &__summary {
    font-size: 0.875rem;
    font-weight: normal;
  }


}
.rep-card-link--email {
  flex: 1 1 calc(100% - 50px);
  line-break: anywhere;
}

.mi-rep-card-container {

  border: 1px solid get-color(mercury);
  border-radius: $global-border-radius;
  font-weight: normal;
  margin-bottom: 20px;
  overflow: hidden;
  padding: 20px 25px;
  width: 100%;
  &--margin {
    margin: 0 20px;
    max-width: calc(100% - 40px);
  }
  a {
    &:hover,
    &:focus,
    &:active {
      color: get-color('dark-aqua');
    }
  }

  &--mobile-only {
    margin: 0px 20px 20px 20px;
    max-width: calc(100% - 40px);
  }

  .rep-description {
    .rep-card-heading {
      align-content: center;
      align-items: center;
      display: flex;
      margin-bottom: 10px;

      h3 {
        margin: 0;
      }

      p {
        margin: 2px 0 0;
      }

      > div {
        display: flex;
        flex: 1 1 calc(100% - 46px);
        flex-wrap: wrap;

        > * {
          flex: 0 0 100%;

        }

        &.mi-user-badge-container {
          padding: 0;

          &.medium {
            flex: 0 0 42px;
          }

          &.large {
            flex: 0 0 46px;

          }
        }
      }
    }

    .mi-user-badge-container {
      margin: 0 10px 0 0;
    }

    i {
      margin-left: auto;
      margin-top: 15px;

      svg {
        width: 60%;
      }
    }
  }

  .rep-contact-modal-launcher {
    padding:0;
    border: 0;
    background-color: transparent;
    color: get-color('boston-blue');
    font-size: .875rem;
    font-weight: 600;

    &:focus,&:active, &:hover {
      border:0;
      outline:0;
      text-decoration: underline;
    }
  }

  .rep-contact-info {
    border-top: 1px solid get-color(mercury);
    font: 0.875rem;
    font-weight: 600;
    padding: 25px 0 0;

    .rep-contact-type-container {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      align-content: center;

      i {
        margin-right: 14px;
        margin-top: 0;
      }

      a {
        color: get-color('aqua');
        font-size: 0.875rem;

        &:hover,
        &:focus,
        &:active {
          color: get-color('dark-aqua');
        }
      }
    }
  }
}

.mi-rep-card-container {
  &--desktop-only {
    display: none;
  }
}

@include media-query(764) {
  .fade-and-rise-appear {
    opacity: 0;
    transform: translateY(20%);
  }

  .fade-and-rise-appear-active {
    opacity: 1;
    transform: none;
    transition: opacity 250ms, transform 350ms;
  }

  .fade-and-rise-appear-done {
    opacity: 1;
    transform: none;
  }
  .mi-rep-card-container {
    &--mobile-only {
      display: none;;
    }

    &--desktop-only {
      display: block;
    }
  }
}
