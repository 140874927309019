@import 'styles/index';

.close-button {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  height: 40px;
  left: 0;
  padding: 0;
  position: absolute;
  text-indent: -999em;
  top: 0;
  width: 40px;
  z-index: 101;

  &__inner {
    height: 100%;
    position: relative;
    width: 100%;

    &::before,
    &::after {
      background-color: black;
      border-radius: 1px;
      content: ' ';
      height: 14px;
      left: 50%;
      position: absolute;
      top: 50%;
      width: 2px;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &:hover,
  &:focus {
    outline: none;
  }
}
@media screen and (min-width:764px) {
  .close-button {
    border-color: get-color(neutral-dark);
    left: 20px;
    top: 20px;
  }
}
