@import 'styles/index';

.contact-bio {
  background-color: rgba(0, 0, 0, 0.9);
  font-family: $proxima;
  height: 100vh;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100vw;
  width: 100%;
  z-index: 299;

  &__close {
    background: get-color('white');
    border: 1px solid get-color(silver-chalice);
    border-radius: 50%;
    height: 32px;
    margin: 0 calc(100% - 32px) 0 0;
    padding: 0;
    position: relative;
    text-indent: -999em;
    width: 32px;

    &::after,
    &::before {
      background-color: get-color('black');
      border-radius: 1px;
      content: ' ';
      height: 2px;
      left: 50%;
      position: absolute;
      top: 50%;
      width: 60%;

    }

    &:active,
    &:focus {
      background-color: get-color(alto);
      outline-color: get-color(loblolly);
    }
    &:hover,
    &:active,
    &:focus {
      background-color: get-color(alto);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__content {
    background-color: get-color('white');
    border: 1px solid get-color('neutral-medium');
    border-radius: $global-border-radius;
    left: 50vw;
    margin: 0;
    max-height: calc(100vh - 40px);
    max-width: 777px;
    overflow: auto;
    padding: 20px;
    pointer-events: none;
    position: relative;
    position: fixed;
    top: 50vh;
    top: calc(50vh - 40px);
    transform: translate(-50%, -50%);
    width: calc(100vw - 40px);
    z-index: 101;
    z-index: 100;
  }


  &::after,
  &::backdrop {
    opacity: 0;
    transition: opacity 0.35s ease-out;
  }

  &[open] {
    pointer-events: auto;

    .contact-bio__content {
      pointer-events: auto;
    }

    &::after,
    &::backdrop {
      opacity: 1;
    }
  }
}

.contact-bio {
  &__img {
    height: 100%;
    min-width: 100%;
    object-fit: cover;
    object-position: center center;
    width: 100%;
  }

  &__img-wrap {
    border-radius: 50%;
    height: 104px;
    margin: 20px auto 0;
    overflow: hidden;
    width: 104px;

  }

  &__bio {
    color: get-color(mine-shaft);
    font-size: 1rem;
    line-height: 19px;
    margin: 0 auto;
    max-width: 575px;

  }

  &__title {
    font-size: 1.5rem;
    font-weight: normal;
    margin: 25px auto 20px;
    text-align: center;
  }
}

.contact-bio::after,
.contact-bio::-webkit-backdrop,
.contact-bio::backdrop {
  content: ' ';
}

@media screen and (min-width:1440px) {
  .contact-bio {
    &__bio {
      line-height: 26px;
    }

    &__img-wrap {
      margin-top: 10px;
    }

    &__content {
      font-weight: normal;
      padding-bottom: 50px;
      top: 50vh;
    }
  }
}
