@import 'styles/index';

.loading-screen {
  background-color: get-color('indigo');
  color: get-color('white');
  font-family: $proxima;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: 100%;
  left: 0;
  line-height: 22px;
  position: fixed;
  top: 0;
  transition: opacity 0.5s ease-in;
  width: 100%;
  will-change: opacity;
  z-index: 1000;
  text-align: center;
  max-width: 100vw;
  max-height: 100vh;
  &.external-redirect {
    background-color: get-color(mine-shaft);
  }

  &__tagline {
      margin-top:45px;
  }

  &__img {
    flex: 0 0 100%;
    padding: 5px 0;
  }

  &__inner-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    position: relative;
    width: 100%;
  }

  &__centered-content {
    margin: 0 auto;
  }
}

.lds-ring {
  align-content: center;
  align-items: center;
  border: 3px solid rgba(get-color('white'), 0.1);
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 300px;
  position: relative;
  text-align: center;
  width: 300px;

  &--small {
    width:12px;
    margin: 0;
    margin-right: 8px;
    display: inline-block;
    transform: translateY(-2px);
    min-height: 12px;
  }
}

.lds-ring div {
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 3px solid get-color('aqua');
  border-color: get-color('aqua') transparent transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 300px;
  margin: 0;
  position: absolute;
  width: 300px;
}

.lds-ring--small div {
  width:  12px;
  height: 12px;

}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}



.fade-load-screen-exit {
  opacity: 1;
}

.fade-load-screen-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.fade-load-screen-exit-done {
  opacity: 0;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
