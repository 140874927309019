.radio-set {
    &__grid {
        display: flex;
        justify-content: space-between;

        .field-wrap {
            margin-top: 0;
            margin-bottom: 5px;
        }
    }
}
