@import 'styles/index';
.dropdown {
    color: get-color('indigo');
    margin: 0;
    list-style: none;
    padding:0;
    text-align: center;
    position: relative;
    font-size:1.25rem;
    flex: 0 0 100%;
    z-index: 1;
    &__value {
        padding-left: 0;
        display: flex;
        align-items: center;
        padding-right: 70px;
        justify-content: center;
    }

    &__icon-wrapper {
        margin-left: 8px;
        transition: transform .3s;
        display: block;
        transform: rotate(270deg);

        &--open {
            transform: rotate(270deg) scaleX(-1);
        }
    }

    &-list-item {
        &__text {
            opacity: .7;
        }
    }

    &__list-item {
        padding:8px 0 8px 20px;
        border-left:3px solid get-color(loblolly);
        display: flex;
        align-items: center;
        position: relative;

        &--complete {
            border-left-color: get-color('confirmation');
        }

        &--started {
            border-left-color: get-color('tangerine');
        }

        &:first-child {
            border-top:1px solid get-color(neutral-dark);
            padding-top:12px;
        }
        &:last-child {
            padding-bottom: 12px;
        }
    }

    &__list {
        padding:0 10px 0 4px;
        margin:0;
        width: 100%;
        position: absolute;
        left: 0;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        transition: opacity 0.1s cubic-bezier(0, 0, 0.38, 0.9), max-height 0.5s cubic-bezier(0, 0, 0.38, 0.9);
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        list-style: none;
        &--open {
            opacity: 1;
    overflow: auto;
    max-height: calc(100vh - 60px);
        }
    }

    &__list-container {
        position: relative;
    }

    .progress-page-title {
        font-size: 1.25rem;
        font-weight: 400;
        margin: 0;
    }
}


.dropdown-list-item__status {
    border-radius: 10px;
    max-height: 20px;
    background-color: get-color('white');
    font-size: 10px;
    font-family: $proxima;
    position: absolute;
    right:0px;
    top:50%;transform: translateY(-50%);
    text-transform: uppercase;
    padding:3px 7px;

    &--complete {
        color:get-color('confirmation')
    }
    &--started {
        color:get-color('tangerine')
    }
    &--notstarted {
        color: rgba(0, 0, 0, .25);
    }
}

.dropdown__status-label {
    padding:3px 7px;
    background-color: get-color('white');
    font-size: 10px;
    max-height: 20px;
    position: absolute;
    right: 10px;
    top:50%;
    transform: translateY(-50%);
    border-radius: 10px;
    text-transform: uppercase;
    &--complete {
        color: get-color('confirmation');
    }
    &--started {
        color: get-color('tangerine');
    }
    &--notstarted {
        color: rgba(0, 0, 0, .25);
    }
}
