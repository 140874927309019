@import 'styles/index';

.resources {
  margin: 20px 20px 0;
  padding: 0;

  &__article-list {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;

    &-item {
      flex: 0 0 100%;
      margin-bottom: 20px;
    }
  }

  &__section-title {
    color: get-color('indigo');
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25;
  }

}



.resources-article {
  background: get-color('portal-bg-color');
  border-radius: 6px;
  line-height: (19/16);
  overflow: hidden;
  text-decoration: none;
  width: 100%;

  &__title {
    color: get-color('indigo');
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 20px;

  }

  &__body {
    color: get-color(silver-chalice);
    display: none;
    font-size: (14rem/16);
  }

  i {
    transition: transform 0.2s ease-in-out;
  }

  &__cta {
    align-items: center;
    color: get-color('black');
    display: flex;
    font-weight: normal;
  }

  &__link {
    display: block;
    height: 100%;
    padding: 20px;
    transition: background-color .2s;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      background-color: get-color(neutral-medium);

      i {
        transform: translateX(5px);
      }
    }
  }
}

@include media-query(572) {
  .resources {
    padding: 0;
  }

  .resources{
    &__article-list-item {
      display: flex;
      flex: 0 0 calc(50% - 10px);
      width: calc(50% - 10px);

      &:only-child {
        flex: 0 0 100%;
      }
    }
  }
}

@include media-query(764) {
  .resources {
    margin-right: 0;
    margin-left: 0;
  }
  .resources-article {

    width: 100%;
    &__title {
      margin-bottom: 4px;
    }

    &__body {
      display: block;
      margin-bottom: 20px;
    }
  }
}
