@import "styles/index.scss";

.text {
    font-family: $proxima;
    font-weight: normal;
}

p.text:last-child,
p.text:only-child {
    margin-bottom: 0;
}


.text--museo {
    font-family: $museo;
    font-weight: 500;
}

.text--extra-large {
    font-size: 40px;
    font-weight: normal;
    line-height: 47px;
}

.text--large {
    font-size: 24px;
    line-height: 28px;
}

.text--medium {
    font-size: 20px;
    line-height: 23px;
}

.text--progress-medium {
    font-size: 20px;
    line-height: 20px;
}

.text--regular {
    font-size: 16px;
    line-height: 19px;
}

.text--small {
    font-size: 14px;
    line-height: 17px;
}

.text--extra-small {
    font-size: 12px;
    line-height: 14px;
}

.semibold {
    font-weight: 600;
}
