@import 'styles/index';
[data-testid="find-lot-container"] {
  max-width: 100vw;
  overflow: hidden;
}
.find-lot-cancel {
  margin-top: 10px;
}

.find-lot-block {
  background-color: get-color('portal-bg-color');
  padding: 25px;

  &--plain,
  &--transparent {
    background-color: transparent;
  }

}

.find-lot-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 18px;

  > * {
    flex: 1 1 auto;

    &:first-child {
      margin-right: 3px;
    }

    &:last-child {
      margin-left: 3px;
    }
  }
}

.find-lot-page-container {
  background-color: get-color('white');
  border: 1px solid get-color(mystic);
  border-radius: 4px;
  min-height: calc(100vh - 60px);
  padding: 2.5rem 5px;
  text-align: center;

  .heading {
    font-size: 1rem;
    margin-bottom: 22px;
  }
}


.lot-association-details-container {
  padding: 20px 0;
  text-align: center;
  width: 100%;

  @include media-query(540) {
    margin: auto;
    padding: 25px 0;
    width: 80%;
  }

  @include media-query(700) {
    margin: auto;
    padding: 40px 0;
    width: 70%;
  }

  @include media-query(1025) {
    margin: auto;
    padding: 60px 0 0;
    width: 60%;
  }

  .button {
    margin-top: 40px;
  }
}

.mobile-search-results {
  list-style: none;
  padding:0;
}

@include media-query(764) {
  .mobile-search-results {
    display: none;
  }
  .find-lot-page-container {
    .heading {
      font-size: 1.5rem;
    }
  }
  .find-lot-block {
    background-color: transparent;
    padding: 33px 16% 43px;
    position: relative;
    &--transparent {
      padding-right: 27px;
      padding-left: 27px;
      max-width: 780px;
      border-top: 1px solid #dde4e8;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    &--plain {
      padding-left: 0;
      padding-right: 0;
    }
    form {
      z-index: 2;
      position: relative;
    }
    &:before {
      position: absolute;
      content: ' ';
      top:13px;
      left:16px;
      width: calc(100% - 32px);
      height: calc(100% - 26px);
      background-color: get-color('portal-bg-color');
    }

    &--transparent,
    &--plain {
      &:before {
        display: none;
      }
    }
  }
}
