@import 'styles/index';

.feedback-modal {
  background-color: get-color('white');
  border: 0;
  border-radius: $global-border-radius;
  left: 50vw;
  max-width: 674px;
  position: fixed;
  text-align: center;
  top: 50vh;
  height: auto;
  top: calc(50vh - 80px);
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 500;
  padding:0;
  margin: 0;
  overflow: visible;

  &__content-wrap {
    padding: 48px 22px 30px;
    border-radius: $global-border-radius;
    overflow-y: auto;
    z-index: 25;
    background-color: get-color('white');
    position: relative;
  }
  &__headline {
    font-size: 1.4rem;;
    font-family: $proxima;
    font-weight: 500;
    color: get-color(mine-shaft);
    &--small {
      font-size: 1rem;;
      font-weight: 400;
      margin-top: 12px;
      color: get-color(tundora);
    }
  }

  .icon--circle {
    margin-right: 0;
    margin: 0 auto 25px;
    svg {
      transform:translate(-50%, -50%) scale(1.75);
    }
  }
}

.rating-stars {
  margin: 20px auto 30px;
  text-align: center;
  &--no-margin {
    margin: 0 auto;
    order:3;
  }
}
.fade-in-appear,
.fade-in-enter {
  opacity: 0;
}
.fade-in-appear-active,
.fade-in-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-in-appear-done,
.fade-in-enter-done {
  opacity: 1;
}

.feedback-modal__success {
  position: absolute;
  left:0;
  height: 100%;
  width: 100%;
  top:0;
  background-color: get-color('white');
  z-index: 3;
  display: flex;
  padding:22px;
  flex-direction: column;
  will-change: opacity;
  justify-content: center;
}

.comment-counter {
  bottom: 10px;
  color: get-color('silver');
  font-size: .75rem;
  font-weight: 500;
  position: absolute;
  right: 5px;
  text-align: right;
}

.feedback-modal::backdrop {
  background: $modalbg;
}

.feedback-modal--ios {
  &:after {
    background-color: $modalbg;
    display: block;
    content: ' ';
    position: fixed;
    top:-35vh;
    left:-25vw;
    width:150vw;
    height:150vh;
    z-index: 1;
  }
}

.feedback-star {
  background: transparent;
  border: 0;
  border-radius: 3px;
  color: get-color(casper);
  min-height: 48px;
  padding:4px;
  i, svg,.icon {
    pointer-events: none;;
  }
  i {
    display: block;
    height: 40px;


  }
  &:focus {
    outline: 0;
    background-color: get-color(neutral-light);
  }
  &:hover,
  &--active {
    color: get-color('tangerine');
    i, svg {
      color: get-color('tangerine') !important;
    }
  }
}
.rating-stars--no-margin {
  .feedback-star i {
    height:32px
  }
}

@media screen and (min-width:764px) {
  .feedback-modal {
    top: 50vh;
    max-height: 450px;
    display: flex;
    height: calc(100vh - 40px);
    flex-direction: column;
    justify-content: center;

    &__content-wrap {
      padding: 60px 20px;
      min-height: 100%;
    }
    &__headline {
      letter-spacing: -0.5px;
    }
    &__form {
      margin: 0 auto;
      max-width: 377px;
    }

    .input-wrap--textarea {
      margin-bottom: 0;
    }

  }
}

@media screen and (min-width:1000px) {
  .feedback-modal {
    max-height: 470px;
  }}
