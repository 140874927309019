@import '../../../styles/index';

.dot {
  background: get-color('neutral-medium');
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 0 7px;
  width: 8px;

  &.active {
    background: get-color('black');
    height: 10px;
    position: relative;
    top: 1px;
    width: 10px;
  }
}
