@import 'styles/index';
.video-modal {
    background-color: $modalbg;
    @media (max-width: 768px) {
        background-color: black;
    }


    .modal-dialog {
        max-width: 640px;
        pointer-events: auto;
    }

    .modal-content {
        background-color: transparent;
        border: none;
        align-items: center;
        width: 640px;
        height: 360px;

        .video-close-icon {
            position: absolute;
            background-color: white;
            top: -50px;
            left: 10px;
            outline: 0;
            width: 40px;
            height:40px;
            border-radius: 50%;
            padding:0 0px 0 0;
            border: 1px solid get-color(silver-chalice);
            .icon {
                transform: rotate(-45deg) translate(-1px, 0px)
            }

            &:hover,&:active, &:focus {
                background-color: get-color(alto);
                outline: 0;
            }
        }

         .youtube-iframe-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
         }

         iframe {
            width: 100%;
            height: 100%;
            pointer-events: auto;
        }
    }
}
