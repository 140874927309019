@import 'styles/index';

.notifications-card-container {
  align-items: center;
  background-color: get-color('light-grey');
  border: 0;
  display: flex;
  width: 100%;

  &--standalone {
    margin-top: 15px;
  }
  .unread & {
    background-color: get-color('white');

    &:hover {
      background-color: get-color('light-grey');
    }
  }

  .card {
    background-color: transparent;
    border: none;
    width: 100%;

    button {
      background-color: transparent;
      border: none;
      padding-bottom: 10px;
      padding-left: 15px;
      padding-top: 10px;
    }

    button:focus {
      box-shadow: none;
      outline: none;
    }


    .notifications-dropdown-card-content {

      padding:5px 15px 15px 15px;

      .text {
        font-weight: 600;
      }

      .notifications-dropdown-link {
        display: flex;
        &:hover,&:focus,&:active,&:visited {
          color: get-color('aqua');
        }
        .icon {
          margin-left: 10px;
          padding-top: 1px;
        }
      }
    }

    .notifications-dropdown-card-content--read {
        .text {
            font-weight: 400;
        }
        a .text,
        a {
            font-weight: 600;
            margin-bottom: 0;
        }
    }
  }

  .notifications-dropdown-card-header {
    padding-right: 60px;
    padding-top: 10px;
    text-align: left;
    width: 100%;


    p {
      font-weight: 600;
      margin-bottom: 0;

    }

    .icon {
      position: absolute;
      right: 15px;
      top: 15px;
    }

    .notifications-dropdown-card-sent-text {
      color: rgba(0, 0, 0, 0.25);
      margin-top: 5px;
    }

    &.open {
      .icon {
        position: absolute;
        right: 15px;
        top: 15px;
        transform: rotate(180deg);
      }
    }
  }

  .notifications-dropdown-card-header--unread {
      p {
          font-weight: 400;
      }

  }
}

@media screen and (max-width:764px) {
  .notifications-card-container {
    &:hover {
      background-color: get-color('white');
    }
  }
}
