@import 'styles/index';
$checkbox-size: 1.25rem;
$radio-size: 1.25rem;

.label {
  color: get-color('black');
  display: flex;
  flex: 0 0 100%;
  font-family: $proxima;
  font-size: 0.625rem;
  font-weight: bold;
  margin-bottom: 4px;
  text-transform: uppercase;
  width: 100%;

  &--radio,
  &--checkbox {
    align-content: center;
    align-items: center;
    display: flex;
    flex: 0 1 auto;
    width: auto;
  }

  &--radio {
    font-size: .75rem;
    font-weight: normal;
    text-transform: none;
  }
}
.comments-box {
  min-height: 160px;
  border: 1px solid #E3E3E3;
  border-radius: 2px;
  font-size: 1rem;
  line-height: 1.25;
  padding: 10px 15px;
  color:get-color('black');

  font-weight: 500;
  font-family: $proxima;
  &::placeholder {
    font-weight: normal;
    font-weight: 500;
    font-family: $proxima;
    color: #C5C5C5;
  }

  &--no-resize {
    resize: none;
  }

  &--with-char-count {
    margin-bottom: 30px;
  }
}
@media screen and (min-width:764px) {
  .comments-box {
    min-height: 120px;
  }
}
textarea {
  width: 100%;
  color:get-color('black');
}
.input {
  &[type="checkbox"] {
    float: left;
    cursor: pointer;
    &[disbled],
    &:disabled {
      opacity: 1;
      cursor: not-allowed;
    }
  }
  &[type="text"],
  &[type="date"],
  &[type="password"],
  &[type="email"],
  &[type="number"],
  &[type="tel"] {
    font-family: $proxima;
    font-weight: 700;
    width: 100%;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    padding: 15px 12px 14px;
    align-content: center;
    font-size: 0.875rem;
    align-items: center;
    color: get-color('black');
    flex: 0 0 100%;
    &::placeholder {
      font-weight: normal;
      color: get-color('gray');
    }
    &:focus {
      background-color: get-color(athens-gray);
      outline: none;
      border-color: get-color(thunder);
      color: get-color('black');
    }
  }
  &:-internal-autofill-selected,
  &:-internal-autofill-selected:focus,
  &:-internal-autofill-selected:hover,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: get-color('black') !important;
    background: get-color('white');
    color: get-color('black') !important;
    border: 1px solid get-color('silver');
    box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
  &[type="text"],
  &[type="password"],
  &[type="number"],
  &[type="email"],
  &[type="tel"] {
    &.input--error {
      background-color: get-color('white');
      border-color: get-color('err');
    }
  }

  &--task {
    border-radius: 50%;
  }
}
.field-instructions {
  color:get-color(dusty-grey);
  font-weight:bold;
  font-family: $proxima;
  margin-top:5px;
  font-size: .875rem;
}
.input-wrap--select {
  &.input-wrap--error {
    border-color: get-color(err);
  }
}
.comments-box.input--error,
textarea.input--error {
  border-color: get-color('err');
  color: get-color('err');
  outline-color: get-color('err');
  &::placeholder {
    color: get-color('err');

  }
  &:focus {
    border-color: get-color('err');
    color: get-color('err');
    outline-color: get-color('err');
  }
}

.field-wrap {
  clear: both;
  margin-top: 18px;
  position: relative;
  text-align: left;

  &--first {
    margin-top: 0;
  }

  .mi-tooltip {
    margin-left: 10px;
    min-height: 20px;
    margin-top: -6px;
    margin-bottom: 6px;
  }

}

.input-wrap {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  &--textarea {
    position: relative;
  }

  p {
    font-size: 0.75rem;
    margin-bottom: 35px;
    margin-top: 35px;
    padding-bottom: 35px;
    text-align: left;

    a {
      color: get-color('black');
      text-decoration: underline;
    }
  }

  &--checkbox {
    margin-bottom: 25px;

    label {
      font-size: 0.875rem;
      font-weight: normal;
      margin: 0;
      text-transform: none;
    }
  }
  &--rel {
    position: relative;
  }
  &--task {
    width: 20px;
    height: 20px;
  }

  &--checkbox-small-mobile-bottom-margin {
    margin-bottom: 15px;
  }

  .label--no-vertical-margin {
    margin: 0;
  }
  .label--disabled {
    cursor: not-allowed;
  }

  .input-wrap--no-vertical-margin {
    margin-bottom: 0;;
    margin-top: 0;
  }

  @keyframes check {
    50% {
      transform: scale(1.2)
    }
  }
  @keyframes checkAndOpacity {
    0% {
      opacity: 0;
    }
    50% {
      transform: scale(1.2);
      opacity: .5;
    }
    100% {
      opacity: 0;
    }
  }

  .checkbox {
    float: left;
    cursor: pointer;
    &--task {
      &:after {
        filter:blur(.25px);
        display: block;
        border-radius: 50%;
        width: 100%;;
        height: 100%;
        content: ' ';
        opacity: 0;
        transform: scale(1.6);
        background-color: get-color(confirmation);

      }
      &-complete {
        &:after {
          animation: checkAndOpacity 650ms ease 0s 1;
        }
        animation: check 500ms ease 0s 1;
      }
    }

    &--radio {
      margin-right: .75rem;

      &-alt {
        margin-right: 0;
      }
    }
  }

  button {
    background: transparent;
    border: 0;
    bottom: 0;
    color: #798691;
    font-family: $proxima;
    font-size: 0.875rem;
    padding: 16px 16px 15px;
    position: absolute;
    right: 0;
    text-transform: uppercase;
  }

  &--border {
    border-top: 1px solid #e4e4e4;
    margin-top: 30px;
    padding-top: 30px;
  }
}

.checkbox--task {
  margin-right: 10px;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.fieldset {
  background: #f4f6f8;
  border: 1px solid #e4e7e9;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 10px;
}

.legend {
  color: get-color('black');
  flex: 0 0 100%;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

$checkbox-size: 1.25rem;

.checkbox {
  flex: 0 0 $checkbox-size;
  height: $checkbox-size;
  margin-right: 12px;
  position: relative;
  width: $checkbox-size;

  &--radio {
    margin-right: .75rem;

    &-alt {
      margin-right: 0;
    }
  }
}

.input--radio,
input[type='radio'],
.input--checkbox,
input[type='checkbox'] {
  -webkit-appearance: none;
  background: #ffffff;
  border: 1px solid get-color('neutral-dark');

  height: 100%;
  margin: 0;
  width: 100%;
}
.input--radio,
input[type='radio'] {

}
.input--checkbox,
input[type='checkbox'] {
  border-radius: .1875rem;
}

.input--task,
input[type='checkbox'].input--task {
  border-radius: 50%;
}




.input-wrap--select,
.input-wrap--border {
  border: 1px solid get-color('neutral-medium');
  border-radius: 1rem;
}

.input-wrap--select {
  background-color: get-color('white');
  font-size: 1rem;
  font-family: $proxima;
  display: flex;
  align-content: center;
  align-items: center;
  color: get-color('black');
  position: relative;
}

.input-wrap--select-like-input {
  border-radius: 6px;
  border-color: get-color('silver');
}


.input-wrap--radio-alt {
  margin-right:0;
  margin-left: auto;
}


select,
.input--select {
  border: 0;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  padding: 25px;
  width: 100%;
}
.input--has-indicator {
  padding-left: 50px;
}

.select--small-padding {
  padding:15px 12px 14px;
}

.input-wrap--select-like-input {
    &::after {
      border:1px solid get-color('aqua');
      position: absolute;
      right:20px;
      top:50%;
      transform: translateY(-80%) rotate(45deg);
      width: 10px;
      height: 10px;
      border-left-color: transparent;
      border-top-color: transparent;
      content: ' ';
      pointer-events: none;
    }
}


$animationTime: .35s;

@keyframes taskcomplete {
  0% {
    background-color: transparent;
    border-color: get-color(confirmation);
  }
  25% {
    background-color: get-color(confirmation);
  }

  50%  {
    border-color: get-color(neutral-medium);
    background-color: get-color(confirmation);

  }

  100%  {
    border-color: get-color(confirmation);
    background-color: get-color(confirmation);

  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.input--checkbox:checked {
  background-color: get-color(boston-blue);
  border: 1px solid get-color(boston-blue);
}
.input--task:checked {
  background-color: get-color(confirmation);
  border: 1px solid get-color(confirmation);
}

.input--task-system-complete:checked {
  background-color: get-color(neutral-dark);
  border: 1px solid get-color(neutral-dark);
}

.input--task-complete:checked {
  animation: taskcomplete $animationTime linear 0s 1;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
.checkbox__indicator--task {
  color:white !important;
  svg {
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
  }
  path{
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
  }
}
.checkbox__indicator--task-complete {
  svg {
    color:white !important;
  }
  path{
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.1s forwards;
  }
}
.input--radio,
input[type='radio'] {
  border-radius: 50%;
}


.input--checkbox:checked,
.input--radio:checked {
  & + .checkbox__indicator {
    display: block;
  }
}


.input--checkbox:focus {
  outline: none;
  &:checked {
    border: 1px solid get-color(loblolly);
  }
}

.input--radio:checked {
  border: 1px solid get-color(loblolly);
  outline: none;

  &:focus {
    border: 1px solid get-color(loblolly);
    box-shadow: 0 0 0 1px get-color(loblolly);
  }
}

.input--radio:focus {
  box-shadow: 0 0 0 1px get-color(loblolly);
  box-shadow: 0 0 0 2px get-color('neutral-dark');
  outline: none;

  &:checked {
    border: 1px solid get-color('neutral-dark');
  }
}


.checkbox-field {
  color: get-color('black');
  flex: 0 0 100%;
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
  /* identical to box height, or 183% */

  label {
    align-items: center;
    display: flex;
    width: 100%;
  }
}
.field-wrap--task {
  width: 20px;
  margin-top:0;
  z-index: 3;
  min-height: 20px;
  position: relative;
}
.input-wrap--task {
  max-width: 20px;
  flex:0 0 20px;
  width:20px;
  min-width: 20px;
  margin-bottom: 0;
  float: left;
}

.checkbox {
  &__indicator {
    content: ' ';
    display: none;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 45%;
    transform: translate(-50%, -50%);

    &--task {
      transform: translate(-50%, -50%);
    }

    &--radio {
      background-color: get-color(boston-blue);
      border: 2px solid get-color('white');
      border-radius: 50%;
      height: 100%;
      top: 50%;
      width: 100%;
    }
  }
}

@include media-query(764) {
  .input-wrap--checkbox-small-mobile-bottom-margin {
    margin-bottom: 25px;
  }
}
