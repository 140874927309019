$time: 350ms;

@media screen and (max-width:764px) {
  main,
  .slide-enter-active,
  .slide-enter,
  .slide-exit {
    transition: transform $time ease-in-out;
  }

  main {
    max-width: 100vw;
  }

  .slide-enter {
    transform: translateX(100vw);
    z-index: 2;

    &-active {
      transform: none;
      z-index: 2;
    }
  }

  .slide-exit {
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(0%);
    width: 100vw;
    z-index: 1;
  }

  .slide-exit-done {
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(100%);
    transition: none;
  }

  .slide-exit-active {
    transform: translateX(-100vw);
    z-index: 1;
  }


  .slide-enter,
  .slide-exit {
    transition: transform $time ease-in-out;
  }

  .slide-enter {
    transform: translateX(100%);
  }

  .slide-enter.slide-enter-active {
    transform: translateX(0%);
  }

  .slide-exit {
    left: 0;
    min-height: 100vh;
    position: absolute;
    top: 0;
    transform: translateX(0%);
    width: 100%;
  }

  .slide-exit-active {
    transform: translateX(-100%);
  }
}
