@import 'styles/index';

.share-modal::backdrop {
  background: $modalbg;
}

.share-modal {
  border: 0;
  display: block;
  background-color: get-color('white');
  border-radius: $global-border-radius;
  bottom: auto;
  height: auto;
  left: 50vw;
  margin: 0;
  max-height: none;
  max-width: 374px;
  overflow: visible;
  padding: 40px 30px 30px;
  position: fixed;
  text-align: center;
  top: 50vh;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 5000;
  &[open] {
    display: block;
    pointer-events: auto;
    &::backdrop {
      opacity: 1;
    }
  }

  .close-button {
    left: 10px;
    top: 10px;
  }

  .field-wrap {
    font-size: 0.75rem;
    text-align: center;
  }


  .input-wrap {
    display: block;
    flex-wrap: nowrap;
    position: relative;

    button {
      max-height: 100%;
      padding: 0;
      top: 0;
      width: 40px;

      z-index: 2;

      svg {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .input[type='text'] {
    font-size: 0.75rem;
    padding-right: 60px;
    z-index: 1;
  }


}

.copy-button {
  position: relative;
  text-indent: -999em;
  width: 40px;

  &__inner {
    display: block;
    height: 100%;
    position: relative;
    text-indent: -999em;
  }

  svg,
  .icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.copy-info {
  color: get-color(lunar-green);
  font-size: 0.75rem;
  margin: 5px 0 0;
  opacity: 0;
  transition: opacity 0.2s;

  &--active {
    opacity: 1;
  }

  &--fade {
    opacity: 0;
  }
}

.share-buttons {
  display: flex;
  justify-content: center;
}

.share-button {
  background: transparent;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 50px;
  margin: 8px;
  overflow: hidden;
  position: relative;
  text-indent: -999em;
  width: 50px;

  svg {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }


  &--fb {
    background-color: #2c4089;
  }

  &--twitter {
    background-color: #0097f0;
  }

  &--email {
    background-color: #36465d;
  }
}
@include media-query(764) {
  .share-button {
    height: 60px;
    margin: 10px;
    width: 60px;
  }
}
