@import "styles/index";

.modal-close-button {
    height: 40px;
    width: 40px;
    position: absolute;
    top: 20px;
    left: 20px;
    border-radius: 20px;
    z-index: 2;
}


.modal-title {
    font-size: 1.25rem;
    font-family: $proxima;
    font-weight: normal;
    text-align: center;;
    margin: 0 auto 15px !important;

    &--small {
        font-size: 1rem;
    }
}

@include media-query(764) {
    .modal-title {
        font-size: 1.5rem;
        &--small {
            font-size: 1.25rem;
        }
    }
}
