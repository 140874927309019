@import 'styles/index';
.acuity-modal {
    background-color: $modalbg;
    .close {
        background: get-color('white');
        border: 1px solid get-color(silver-chalice);
        border-radius: 50px;
        font-size: 32px;
        font-weight: 200;
        height: 42px;
        left: 15px;
        margin: 0 !important;
        opacity: 1;
        padding: 0 !important;
        position: absolute;
        top: 15px;
        width: 42px;
        z-index: 99;



        span {
          display: none;
        }

        &::before {
          color: get-color('boston-blue');
          content: url('data:image/svg+xml;charset=UTF-8, <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="21" cy="21" r="21" fill="none"/><path d="M27.5625 27.5625L14.4375 14.4375" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M27.5625 14.4375L14.4375 27.5625" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        }

        &:active,
        &:focus,
        &:hover {
          background-color: get-color(alto);
          outline: 0;
        }
      }

    .modal-dialog {
        max-width: 1000px;
    }

    @media (max-width: 764px) {
      .modal-dialog {
        margin: 1.5rem;
      }

      .modal-content {
        border-radius: 1rem;
      }
    }

    .modal-content {
        border: none;
        align-items: center;
        width: 1000px;

        .modal-header {
            border: none;
        }

        .modal-body {
            padding: 2rem;
            width: 100%;
        }

         iframe {
            width: 100%;
            height: 600px;
        }

        @media (max-width: 764px) {
          .modal-body {
            padding: 3rem 1rem;
         }

            iframe {
                width: 100%;
            }
        }
    }
}
