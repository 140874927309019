@import 'styles/index';

.tablinks-wrapper--mobile {

    .tabs__nav-list--mobile {
        list-style: none;
        padding: 0;
    }

    .status-indicator {
        vertical-align: middle;
    }

    .tablink-wrapper {
        padding: 0;

        cursor: pointer;

        &:last-of-type {
            border-bottom: 0;
        }

        &:focus,
        &:active {
            background: get-color('neutral-light');
        }


    }
}

.tablink {
  color: get-color('black');
  display: block;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  border-left: 3px solid get-color(loblolly);
  padding: 17px 10px 17px 17px;
  position: relative;

  &::before {
      height: 2px;
      content: ' ';
      position: absolute;

      bottom:0;
      background-color:  get-color('light-grey');
      left:18px;
      width:calc(100% - 40px);
  }

  &::after {
      content: url('data:image/svg+xml;charset=UTF-8, <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7 6.26286L1 11.5257" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      float: right;
      font-weight: 100;
      position: relative;
  }

  &--complete {
    border-left-color: get-color('confirmation');
  }

  &--started {
    border-left-color: get-color('tangerine');
  }

  &__status {
      background-color: get-color('white');
      border-radius: 10px;
      max-height: 20px;
      padding:3px 7px;
      font-size: 10px;
      font-family: $proxima;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-60%);
      text-transform: uppercase;
      color: rgba(0, 0, 0 ,.25);
      &--complete {
          color: get-color('confirmation');
      }
      &--started {
          color: get-color('tangerine');
      }
  }
}
