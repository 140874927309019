@import '../functions/breakpoints';

/// @parameter {number} $min-width [0] - Minimum size of the media query
/// @parameter {number} $max-width [null] - Maximum size of the media query
/// @since 1.0.0
@mixin media-query($min-width: 0, $max-width: null) {
  $breakpoints: breakpoints();
  $query: screen;

  @if index($breakpoints, $min-width) != null {
    @if $min-width > 0 {
      $min-width: ($min-width) + 'px';
      $query: $query + ' and (min-width: #{$min-width})';
    }
  } @else {
    @error 'ERROR - min-width (#{$min-width}px) is not an existing breakpoint. Supported breakpoints are: #{$breakpoints}';
  }

  @if $max-width {
    @if index($breakpoints, $max-width) != null {
      $max-width: ($max-width) + 'px';
      $query: $query + ' and (max-width: #{$max-width - 1})';
    } @else {
      @error 'ERROR - max-width (#{$max-width}px) is not an existing breakpoint. Supported breakpoints are: #{$breakpoints}';
    }
  }

  @media #{$query} {
    @content;
  }
}
