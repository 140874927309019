@import 'styles/index';

.anchored-mobile-nav {
  background-color: get-color('indigo');
  bottom: 0;
  display: flex;
  font-size: 0.75rem;

  height: 85px;

  left: 0;
  max-width: 100vw;
  overflow: hidden;
  padding: 19px 20px;
  position: fixed;
  width: 100%;
  z-index: 100;

  &__list {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    &--condensed {
      justify-content: space-around;
    }

    &-item {
      margin: 0;
    }
  }
}

.anchored-nav-link {
  background: transparent;
  border:0;
  padding:0;
  color: get-color(slate);

  display: block;
  text-align: center;

  text-decoration: none;

  &__icon {
    display: block;
    margin: 0 auto 5px;
  }

  &__text {
    display: block;
    flex: 0 0 100%;
    width: 100%;
  }

  &:hover,
  &:active,
  &:focus {
    color: get-color('white');
    text-decoration: none;
    border:0;
    outline: none;
  }


  &--active {
    color: get-color('white');

    &:hover {
      color: get-color('white');
    }
  }

  &--more-open {
    color: get-color(slate);
  }
}


@media screen and (min-width: 764px) {
  .anchored-mobile-nav {
    display: none;
  }
}
