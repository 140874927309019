@import 'styles/index';

.accordion-group-header-container {
    p {
        color: get-color('steel-blue');
        text-decoration-line: underline;
        text-align: left;
        border-bottom: 1px solid get-color('mercury');
        line-height: 0.1em;
        margin-bottom: 0px;

        span {
            background: get-color('white');
            padding:0 22px;
        }
    }
}