.accordion-group-container {

    margin: 15px 0px 5px 0px;

    .card {
        border: none;
    }

    .card-body {
        padding: 0;
    }

    .btn-accordion-group-header {
        padding: 10px 0px 10px 0px;
    }
}
