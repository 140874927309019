@import 'styles/index';
.card.personal-checklist-card {
    background: get-color('portal-bg-color');
    outline: none;
    border: 1px solid get-color(mercury);
    border-radius: 6px;
    padding: 15px 20px 15px 20px;
    margin: 20px 0px;

    .personal-checklist-toggle {
      background: none;
      border: none;
      padding: 0;

      .personal-checklist-toggle-content {
        display: flex;

            .heading {
                text-align: left;
                width: 87%;
                display: flex;
                color: get-color(tundora);
            }

            .icon {
              margin-left: auto;
              margin-right: 0px;
            }
      }
    }

    .mobile-add-task-button {
       .button__text {
         margin-top: 2px;
       }
    }

    .personal-checklist-toggle-collapse-container {
      background: get-color('white');
      margin-top: 15px;
      border: 1px solid get-color(mercury);
      border-radius: 3px;

      .card {
        border: none;
      }

      .accordion {
          margin: 0px;
      }
    }

    @media (max-width: 764px) {
      background: get-color(athens-grey);
      border: none;
      border-radius: 0px;

      .personal-checklist-toggle {
        .personal-checklist-toggle-content {
            .heading {
                width: 83%;
            }

            .icon {
              margin-left: auto;
              margin-right: 0px;
            }
        }
      }
    }
  }
