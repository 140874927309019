@import '../../styles/index';

.customer-dashboard-container {
  display: flex;
  flex-direction: column;


  &--desktop {
    display: none;
    margin: 20px;
  }


  .progress-dashboard-container {
    background: get-color('white');
    border-radius: 6px;
    margin-bottom: 20px;
  }
}

.dashboard-card {
  padding: 20px 0;

  &--no-mobile-padding {
    padding: 0;
  }

  &__title {
    color: get-color('black');
    font-size: 1rem;
    line-height: 1;
    margin: 0 20px 20px 0;

    &--mobile-page-title {
      background-color: get-color(neutral-light);
      padding: 20px;
      font-family: $proxima;
      font-size: 1.25rem;;
      margin:0;
      color:get-color('indigo');
      position: relative;
      text-align: center;
    }

    &--indent {
      margin-left: 20px;
    }
  }
}



@include media-query(764) {
  .dashboard-card__title--mobile-page-title {
    padding:0;

    background-color: transparent;
    font-family: $museo;
    text-align: left;
  }

  .myhome-container {
    background: get-color('white');
    border: 1px solid get-color('neutral-medium');
    border-radius: 6px;
    margin-bottom: 20px;
  }

  .dashboard-card {
    &--no-mobile-padding {
      padding: 20px;
    }
  }
}

.welcome-content{
  &__icon {
    color:get-color('indigo');
    position: relative;

    & + p {
      font-weight: 600;
    }
  }
  &__alert-dot {
    position: absolute;
    top:50%;
    border-radius: 50%;;
    left:50%;
    height: 15px;
    z-index: 2;
    width: 15px;
    background-color: get-color('err');
    transform: translate(calc(-50% + 8px), calc(-50% - 18px));
  }
}

.welcome-modal--desktop {
  background: $modalbg;
  text-align: center;

  .modal-content {
    border-radius: 16px;
    height: 760px;
    margin: auto;
    max-width: 820px;

    padding: 40px 20px;
    width: 100%;
  }

  .modal-body {
    margin: auto;
    max-height: 600px;
    max-width: 680px;
    padding: 0;
    position: relative;
    width: 100%;


    &::before {
      background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0) 100%, transparent 90%);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 20%;
      z-index: 99;
    }

    &::after {
      background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0) 100%, transparent 90%);
      bottom: 0;
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 20%;
      z-index: 99;
    }

    .welcome-screen {
      height: 648px;
      margin: auto;
      max-width: 480px;
      width: 100%;

      &:focus {
        outline: none;
      }

      .welcome-image-wrapper {
        height: 300px;
        padding-top: 25px;

        svg {overflow: visible;}
      }

      .welcome-content-wrapper {
        height: 300px;
        margin: auto;
        max-width: 350px;
        position: relative;
        width: 100%;

        @include media-query(1025) {
          max-width: 400px;
        }

        &--no-image {
          height: calc(100% - 70px);
        }

        .welcome-content {
          bottom: 40px;
          position: absolute;

          &--no-image {
            height: calc(100% - 100px);
          }

          .text--large {
            font-weight: 600;
            line-height: 28px;
          }

          .text--medium {
            font-weight: 500;
            line-height: 19px;
          }
        }

        .button {
          height: 42px;
          margin: 20px auto;
          width: 226px;
        }
      }
    }

  }

  .slick-slider {
    height: 648px;
  }

  .screen1 {
    .welcome-image-wrapper {
      img {
        margin: auto;
        padding: 60px 0;
        width: 180px;
        height: 300px;
      }
    }
  }

  .screen2,
  .screen3,
  .screen4 {
    .welcome-image-wrapper {
      img {
        margin: auto;
        max-height: 326px;
        width: 450px;
      }

      &::after {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 100%);
        content: '';
        display: block;
        height: 150px;
        margin-top: -150px;
        position: relative;
      }
    }
  }

  .slick-initialized .slick-slide {
    outline: none;
    overflow: hidden;
  }

  .slick-dots {
    bottom: 50px;

    li {
      margin: 0 -4px;

      button::before {
        color: get-color('neutral-medium');
        font-size: 8px;
        opacity: 1;
      }

      &.slick-active {
        button::before {
          color: get-color('black');
        }
      }
    }
  }
}

.welcome-modal--mobile {
  background: $modalbg;
  text-align: center;

  .modal-body {
    padding: 0;
  }

  .modal-content {
    border-radius: 6px;
    margin: auto;
    width: 85%;
  }

  .slick-slider {
    height: 540px;
  }

  .welcome-screen {
    height: 500px;

    &:focus {
      outline: none;
    }

    .welcome-image-wrapper {
      height: 250px;

      &--no-image {
        height: 100%;
      }
    }

    .welcome-content-wrapper {
      height: 250px;
      position: relative;

      &--no-image {
        height: 100%;
      }

      .welcome-content {
        bottom: 0;
        margin: auto;
        padding: 0 20px;
        position: absolute;
        width: 100%;

        p:first-child {
          font-weight: 600;
        }
      }

      .button {
        height: 42px;
        margin: 40px auto 10px;
        width: 226px;
      }
    }
  }

  .screen1 {
    .welcome-image-wrapper {
      img {
        margin: auto;
        padding: 10px 0;
        width: 280px;
      }
    }
  }

  .screen2,
  .screen3 {
    .welcome-image-wrapper {
      img {
        margin: auto;
        width: 280px;
      }

      &::after {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 100%);
        content: '';
        display: block;
        height: 180px;
        margin-top: -150px;
        position: relative;
        width: 100%;
      }
    }
  }

  .slick-initialized .slick-slide {
    outline: none;
    overflow: hidden;
  }

  .slick-dots {
    bottom: 15px;

    li {
      margin: 0 -4px;

      button::before {
        font-size: 8px;
      }
    }
  }
}

.welcome-modal--desktop .modal-body .welcome-screen .welcome-content-wrapper .welcome-content--no-image {
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);

  button:last-child {
    margin-bottom: 0;
    margin-top: auto;
  }
}

.notifications-preference-form {
  background-color: get-color('portal-bg-color');
  border-radius: $global-border-radius;
  margin: 0 auto;
  max-width: 260px;
  padding:25px 25px 10px;
  width: 100%;

  .field-wrap .mi-tooltip {
    margin-bottom: 0;
    margin-top: 0;
  }
}

@include media-query(764) {
  .notifications-preference-form {
    padding:40px 25px 25px 45px;

    .field-wrap .mi-tooltip {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

@include media-query(764) {
  .customer-dashboard-container {
    &--desktop {
      display: block;
    }

    &--mobile {
      display: none;
    }
  }
}

.customer-dashboard-container--mobile {
  background-color: get-color('white');

  .dashboard-card {
    background: get-color('white');
    margin: auto;
    width: calc(100vw - 40px);
    padding-bottom: 0;
    margin-bottom: -20px;

    &--no-mobile-padding {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .progress-dashboard-container--mobile {
    background: get-color('white');
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 100vw;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    border: 0;
    border-radius: 6px;
  }
}

.main--progress {
  padding-top: 60px;
}


.main--has-bottom-docked-nav-on-mobile {
  .tabs__content {
    margin: auto;
    padding: 0;
    width: 100vw;
    max-width: 100%;

    @include media-query(764) {
      animation: none;
      padding: 20px 0;
      width: 100%;
    }
  }
}

@include media-query(764) {
  .main--has-bottom-docked-nav-on-mobile {
    .tabs__content {
      margin-top: 0;
    }
  }
}
