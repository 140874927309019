.progress-accordion-container {
  .card {
    border: none;
    border-radius: 6px;
    padding: 0 10px;

    .card-body {
      margin-left: 13px;
      margin-right: 13px;
      padding: 0;
    }
  }

  .btn:focus {
    box-shadow: none;
  }

  @media screen and (max-width:764px) {
    .card {
      border-radius: 0;
    }
  }
}
