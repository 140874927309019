@import 'styles/index';

.tabs-accordion-section-container {
  padding-bottom: 20px;
}

.accordion-card-container {
  .accordion-card-image {
    img {
      border-radius: 3px;

      display: block;
      height: auto;
      width: 100%;

      &.img-90 {
        width: 90%;
      }

      &.img-80 {
        width: 80%;
      }

      &.img-70 {
        width: 70%;
      }

      &.img-60 {
        width: 60%;
      }

      &.img-50 {
        width: 50%;
      }
    }
  }

  .accordion-card-content {
    @include html-styles;
    padding: 20px;

  }
}
