@import '../../../styles/index';

.myhome-container {
  font-family: $proxima;

  &__img-wrap {
    background-color: get-color(neutral-medium);

    display: none;
    height: 0;
    border-radius: 3px;
    overflow: hidden;
    padding-bottom: 66.67%;
    position: relative;

    &:first-child {
      display: block;
    }

    &::after {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #363636 100%);
      background-blend-mode: multiply;
      content: ' ';
      height: 100%;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__img-launcher {
    display: contents;
  }

  &__img {
    height: 100%;
    min-width: 100%;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    width: 100%;
  }

  &__address {
    bottom: 30px;
    color: get-color('white');
    font-size: 1rem;
    font-weight: 600;
    left: 30px;
    line-height: 1.1875rem;
    margin: 0;
    max-width: calc(100% - 60px);
    padding: 0;
    position: absolute;
    text-align: left;
    z-index: 2;
  }

  &__street-address {
    display: block;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.2;
    margin: 0 0 5px;
  }
}

.myhome-container__img-launcher--empty {
  display: none;
}

@include media-query(764) {
  .myhome-container {
    &__img {
      position: relative;
      transition: transform 0.5s ease-in-out;
    }

    &__img-grid {
      &--more-than-1 {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 77fr 23fr;
        grid-template-rows: 145px 145px;
      }
    }

    .myhome-container__img-launcher--empty {
      display: block;
      display: flex;
      justify-content: center;
      align-content: center;
      justify-items: center;
      align-items: center;
    }

    &__img-wrap {
      padding-bottom: 50%;
      display: block;

      &--more-than-1 {
        height: auto;
        padding-bottom: 0;

        &:first-child {
          grid-row-end: 3;
          grid-row-start: 1;
        }
      }

    }

    &__img-launcher {
      background-color: transparent;
      border: 0;
      display: block;
      font-family: $proxima;
      font-size: 1.25rem;
      font-weight: 500;
      height: 100%;
      padding: 0;
      position: relative;
      width: 100%;

      &--single {
        position: absolute;
      }

      &--empty {
        background-color: get-color('white');
        border: 1px solid get-color(neutral-medium);
        color: get-color('aqua');
        background: white;
        font-size: 1.25rem;
        color: get-color('aqua');
        &:hover,
      &:focus,
      &:active {
          color: get-color('aqua');
        }
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;

        .myhome-container__img {
          transform: scale(1.05);
        }

        .myhome-container__img-launcher-bg--dark {
          background-color: rgba(0, 0, 0, 0.75);
          text-decoration: underline;
        }
      }
    }

    &__img-launcher-bg {
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      color: get-color('white');
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      transition: background-color 0.2s ease-in;
      width: 100%;
      z-index: 3;

    }

    &__address {
      font-size: 1.5rem;
      font-weight: 400;
    }

    &__street-address {
      font-size: (40rem/16);
      font-weight: 500;
      line-height: 1;
    }
  }
}

.myhome-container__img-wrap {
  .image-gallery {
    position: relative;
    top: 40px;
  }
}


.download-all-button {
  position: absolute;
  right:0;
  top: -4px;
  outline: none;
  &:focus {
    outline: none;
    text-decoration: underline;
  }
}

.photo-gallery-page {
  .photo-gallery-container {
    position: relative;
    .photo {
      height: 120px;
      margin: 10px;
      overflow: hidden;
      width: 150px;

      img {
        transition: transform 0.5s;

        &:hover {
          transform: scale(1.05);
          }
      }
    }
  }
}

.photo-gallery--modal {
  .modal-dialog {
    margin: 0;
  }

  &.photo-gallery-modal--mobile .modal-dialog {
    max-width: 100%;
  }

  .modal-content {
    width: 100vw;
    height: 100vh;
    background: get-color('white');
    border-radius: 16px;
    margin: auto;

    .modal-body {
      overflow-y: auto;
    }
  }

  .modal-header {
    background: get-color(link-water);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 35px;

    .close {
      background: get-color('white');
      border-radius: 50px;
      font-size: 32px;
      font-weight: 200;
      height: 42px;
      left: 30px;
      overflow: hidden;
      margin: 0;
      opacity: 1;
      padding: 0;
      position: absolute;
      top: 15px;
      width: 42px;
      z-index: 99;
      border: 1px solid get-color(silver-chalice);
      &:before {
        content: url('data:image/svg+xml;charset=UTF-8, <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="21" cy="21" r="21" fill="none"/><path d="M27.5625 27.5625L14.4375 14.4375" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M27.5625 14.4375L14.4375 27.5625" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      }
      &:focus,&:active {
        outline-color: get-color(loblolly);
      }
      &:hover {
        background-color: get-color(alto);
        &:before {
          color:black;

        }
      }
      span {
        display: none;
      }


    }
  }
}

@include media-query(764) {
  .photo-gallery--modal {
    background: get-color('black');

    &.photo-gallery-modal--mobile .modal-dialog {
      max-width: 100%;

      .modal-content {
        width: 100vw;
        height: 100vh;
      }
    }

    &.photo-gallery-modal--desktop .modal-dialog .modal-content {
      width: 100%;
      height: calc(100vh - 100px);
    }

    .modal-dialog {
      margin: auto;

      .modal-content {
        background: get-color('white');
        border-radius: 16px;
        margin: auto;
        max-width: 806px;
        padding-bottom: 10px;
      }
    }
  }
}
