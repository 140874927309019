@import 'styles/index';

[data-tooltip] {
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-size: 1rem;
  position: relative;
  text-decoration: underline;
  z-index: 11;
  align-items: center;

  svg {
    background: get-color('body-text');
    border: 1px solid get-color('body-text');
    border-radius: 50%;
    color: get-color('white');
    display: block;
  }

  &:hover,
  &:focus {
    svg {
      background: get-color('white');
      border: 1px solid get-color('body-text');
      border-radius: 50%;
      color: get-color('body-text');
    }
  }

  &.inverted {
    svg {
      background: get-color('white');
      border: 1px solid get-color('body-text');
      color: get-color('body-text');
    }
    &:hover,
    &:focus {
      svg {
        background: get-color('body-text');
        border: 1px solid get-color('body-text');
        color: get-color('white');
      }
    }
  }
}

[data-tooltip].tooltip-block {
  border-bottom: 0;
}

[data-tooltip].capitalize-tooltip::after {
  text-transform: capitalize;
}

[data-tooltip]::after,
[data-tooltip]::before {
  -webkit-transition: opacity 0.4s cubic-bezier(0.14, 0.58, 0.18, 1) 0s;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s cubic-bezier(0.14, 0.58, 0.18, 1) 0s;
  visibility: hidden;
}

[data-tooltip]::after {
  -webkit-transform: translateX(-50%);
  background-color:get-color('white');
  border-radius: 3px;
  border: 1px solid get-color('neutral-medium');
  bottom: 175%;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  color: get-color('black');
  content: attr(data-tooltip);
  font-size: 0.875rem;
  font-size: 14px;
  font-weight: 500;
  left: 100px;
  line-height: 1.4;
  min-width: 18rem;
  min-width: 280px;
  padding: 16px;
  padding: 1rem;
  position: absolute;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  transform: translateX(-50%);
  white-space: pre-line;
  width: 100%;
  z-index: 11;
}

[data-tooltip]::before {
  -webkit-transform: rotate(45deg);
  background: get-color('white');
  border: 1px solid rgba(0, 0, 0, 0.18);
  border-width: 0 1px 1px 0;
  bottom: 175%;
  content: '';
  height: 16px;
  height: 1rem;
  left: 50%;
  margin: 0 0 -0.5rem -0.5rem;
  margin: 0 0 -8px -8px;
  opacity: 0;
  position: absolute;
  transform: rotate(45deg);
  visibility: hidden;
  width: 16px;
  width: 1rem;
  z-index: 12;
}

[data-tooltip].tooltip-block::after,
[data-tooltip].tooltip-block::before {
  bottom: 100%;
  text-align: center;
}

[data-tooltip].show::after,
[data-tooltip].show::before,
[data-tooltip]:hover::after,
[data-tooltip]:hover::before,
body:not(.is-ios-safari) [data-tooltip]:focus::after,
body:not(.is-ios-safari) [data-tooltip]:focus::before {
  opacity: 1;
  visibility: visible;
}
.mi--tooltip--ten[data-tooltip]::after {
  transform: translateX(calc(-50% - 110px));
}
.mi--tooltip--twenty[data-tooltip]::after {
  transform: translateX(calc(-50% - 120px));
}
.mi--tooltip--thirty[data-tooltip]::after {
  transform: translateX(calc(-50% - 130px));
}
.mi--tooltip--fourty[data-tooltip]::after {
  transform: translateX(calc(-50% - 140px));
}
.mi--tooltip--fifty[data-tooltip]::after {
  transform: translateX(calc(-50% - 150px));
}
.mi--tooltip--sixty[data-tooltip]::after {
  transform: translateX(calc(-50% - 160px));
}
.mi--tooltip--seventy[data-tooltip]::after {
  transform: translateX(calc(-50% - 170px));
}
.mi--tooltip--eighty[data-tooltip]::after {
  transform: translateX(calc(-50% - 180px));
}
.mi--tooltip--ninety[data-tooltip]::after {
  transform: translateX(calc(-50% - 190px));
}
.mi--tooltip--hundred[data-tooltip]::after {
  transform: translateX(calc(-50% - 200px));
}

.mi--tooltip--hundredten[data-tooltip]::after {
  transform: translateX(calc(-50% - 210px));
}

.mi--tooltip--hundredtwenty[data-tooltip]::after {
  transform: translateX(calc(-50% - 220px));
}

.mi--tooltip--hundredthirty[data-tooltip]::after {
  transform: translateX(calc(-50% - 230px));
}
