@import 'styles/index';

.checklist-dropdown-section-container {

    &:nth-child(2) {
        border-top: 1px solid get-color('neutral-medium');
        margin-top: 5px;
        padding-top: 15px;
    }

    .checklist-dropdown-section-title {
        color: get-color('black');
        font-size: 12px;
        width: 70%;
        margin: auto;

        &--page {
            width: 88%;
        }
    }
}