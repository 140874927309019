@import 'styles/index';

.notifications-toggle-container {
    position: absolute;
    right: 88px;
    top: 16px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    svg {
        overflow: inherit;
    }
    &.active,
    &:focus,
    &:active,
    &:hover {
        background-color: rgba(white, .15);
        outline: 0;
    }
}

