@import 'styles/index';

.lot-search-results{
    &__title {
        font-size: 1rem;
        margin:0 auto 30px;
    }

    &__select-lot-button {
        @include nakedbutton;
        font-weight: bold;
        color: get-color('aqua');
        padding:0;
    }
}
@include media-query(764) {
    .lot-search-results {
        border-top:1px solid #C2C8CC;
        &__title {
            font-size: 1.5rem;
        }
    }
}

.sortable-table-mobile-result {
    border:0 !important;
    margin: 4px auto 8px;
    border-radius: 6px !important;
    background-color: get-color(neutral-light) !important;
    .icon {
        min-width: 28px;
    }
    .heading {
        margin:0 !important;
        font-size: 1rem !important;

    }
    .collapse {
        background-color: get-color(neutral-light);
    }
    &__icon {
        &--open {
            transform: rotate(180deg);
        }
    }
    &__button {
        color: get-color('indigo');
        border:0;
        @include nakedbutton;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background-color: get-color(neutral-light);
        padding: 10px 5px 10px 14px;

        &--open {
            color: get-color('white');
            background-color: get-color('indigo');
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        padding:10px 5px 10px 14px;
        font-size: .875rem;

        p {
            font-weight: bold;
            color: get-color('black');
            margin: 4px 5px 10px;
            span {
                font-weight: normal;
            }
        }

        button {
            @include nakedbutton;
            padding:0;
            font-weight: bold;
            color: get-color('aqua');
        }
    }
  }
