@import '../../../styles/index';

.progress-dashboard-container {
    border: 1px solid get-color(mercury);
    padding: 20px;
}

@include media-query(764) {
    .progress-dashboard-container {
        padding-bottom: 0;
    }
}
