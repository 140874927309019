@import 'styles/index';


.invite-form-container {
    width: 50%;
    margin: 50px auto;

    .invite-form {
        &--busy {
            opacity: 0.2;
            pointer-events: none;
            position: relative;
        }

        .invite-email {
            opacity: 0.5;
        }
    }

    .password-requirements-text {
        margin: 30px 0px;
    }

    .invalid-invite-wrapper {
        text-align: center;

        .icon {
            display: block;
            text-align: center;
            margin-bottom: 25px;
        }

        .invalid-invite-message {
            text-align: center;
        }

        .link-to-login-button {
            background: get-color('aqua');
            border-radius: 3px;
            color: get-color('white');
            font-size: 16px;
            font-weight: 500;
            padding: 14px 20px;
            display: block;
            &:hover,
            &:active {
                background: get-color('dark-aqua');
                text-decoration: none;
            }

            &:focus {
                border: 1px solid get-color('neutral-dark');
                outline: 0;
            }
        }
    }

    @media (max-width: 764px) {
        width: 100%;
    }
}