@import 'styles/index';

.checklist-toggle-container {
  background-color: transparent;
  border: 0;
  border-radius: 500px;
  cursor: pointer;
  display: flex;
  padding: 8px;
  position: absolute;
  right: 130px;
  top: 15px;
  width: 10%;
  min-width: 98px;

  &.open,
  &:focus,
  &:active,
  &:hover {
    outline: 0;
    background-color: get-color(shuttle-gray);
  }

  .checklist-toggle-icon {
    background-color: get-color('white');
    border-radius: 50%;
    height: 18px;
    margin-right: 5px;
    text-align: center;
    width: 18px;
    min-width: 18px;
  }

  .icon {
    left: 12%;
    position: absolute;
    top: 25%;
  }

  p {
    margin: 0;
  }
}
