@import 'styles/index';

.tabset {
  text-align: center;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  &__tabs {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0 30px;
    max-width: 100%;
    overflow: hidden;
    &::after {
      background: get-color(neutral-medium);
      bottom: 0;
      content: ' ';
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }

  &__tab {
    overflow: hidden;
    > * {
      margin: 13px 16px;
    }
    &[aria-hidden='true'] {
      display: none;
    }
  }

  &__tab-button {
    @include nakedbutton;
    &:active,
    &:active span {
      background-color: get-color('white');
      color: get-color('black');
    }
    span {
      background-color: get-color(neutral-light);
      display: flex;
      align-items: center;
      white-space: nowrap;
      color: get-color('black');
    }
    padding: 0;
    font-size: .875rem;
    line-height: 1.2;
    align-items: stretch;
    display: flex;
    position: relative;
    z-index: 2;

    & + .tabset__tab-button {
      span {
        border-left-color: transparent;
        &.active {
          border-left-color: get-color(neutral-medium);
        }
      }
    }

    span {
      border: 1px solid get-color(neutral-medium);

      padding: 12px 25px;

      &.before {
        border-right-color: transparent;
      }

      &.after {
        border-left-color: transparent;
      }
    }

    &--active {
      span {
        background-color: get-color('white');
        border-top-left-radius: $global-border-radius;
        border-top-right-radius: $global-border-radius;
        position: relative;
        z-index: 3;
      }
    }

    // little filler blocks
    &--before {
      &::before {
        background-color: get-color(neutral-light);
        border-top: 1px solid get-color(neutral-medium);
        content: ' ';
        height: 5px;
        right: 0;
        position: absolute;
        top: 0;
        transform: translateX(100%);
        width: 5px;
        z-index: 1;
      }
      &:hover {
        &::before {
          background-color: get-color('white');
        }
      }
    }
    &--after {
      z-index: 1;
      &::before {
        background-color: get-color(neutral-light);
        border-top: 1px solid get-color(neutral-medium);
        content: ' ';
        height: 5px;
        left: 0;
        position: absolute;
        top: 0;
        transform: translateX(-100%);
        width: 5px;
        z-index: 1;
      }
      &:hover {
        &::before {
          background-color: get-color('white');
        }
      }
    }
    // end the little filler flocks

    &:active,
    &:hover,
    &:focus {
      outline: none;
      color: get-color('black');
      span {
        color: get-color('black');
      }
    }

    &:hover,
    &--active {
      background-color: get-color('white');;
      color: get-color('black');
    }

    &--active {
      border-bottom-color: get-color('white');
      border-top-left-radius: $global-border-radius;
      border-top-right-radius: $global-border-radius;
      pointer-events: none;
      position: relative;
      z-index: 2;

      span {
        border-bottom-color: get-color('white');
      }
    }

    &:first-child {
      span {
        border-top-left-radius: $global-border-radius;
      }

    }

    &:last-child {
      span {
        border-top-right-radius: $global-border-radius;
      }

    }
  }
}

@include media-query(764) {
  .tabset {
    &__tab {
      > * {
        margin: 0;
      }
    }
    &__tabs {
      padding:0;
      max-width: 630px;
      margin:0 auto;
    }
    &__tab-content {

      margin: 0 auto;
      .find-lot-block {
        border:1px solid get-color(neutral-medium);
        border-top:0;
        border-radius: $global-border-radius;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        max-width: 630px;
        margin:0 auto;
        &--plain {
          border:0;
          border-radius: 0;
          background-color: transparent;
          max-width: none;
          margin-right: 30px;
          margin-left: 30px;
          padding-top:25px;
          border-top:1px solid #C2C8CC;
          margin:40px 30px;
        }
        &--transparent {
          max-width: 780px;
      border-top: 1px solid #dde4e8;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      transform: translateY(-1px);
        }
      }
    }
    &__tab-button {
      flex:1 0 33.3%;
      span {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
    }
  }
}
