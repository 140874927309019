.lot-id-search {
    .label--radio {
        font-size: .875rem;
    }

    .input--number {
        -webkit-appearance: none !important;
        margin: 0;
        -moz-appearance:textfield;
        &:hover,
        &:focus {
            -webkit-appearance: none !important;
            margin: 0;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        // Show number picker on focus
        &:focus::-webkit-inner-spin-button,
        &:focus::-webkit-outer-spin-button,
        &:hover::-webkit-inner-spin-button,
        &:hover::-webkit-outer-spin-button {
            -webkit-appearance: none !important;
            margin: 0;
        }
    }
}
