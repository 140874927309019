@import '../../../styles/index';

.dashboard-notifications-button-container {
    margin: 20px;

    .dashboard-notifications-button {
        border-radius: 6px;
        border: 1px solid get-color('neutral-medium');
        padding: 18px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: get-color('neutral-medium');

        .icon {
            margin-right: 5px;
            margin-bottom: 5px;

            svg {
                overflow: inherit;
            }
        }
    }

    .text {
        margin-top: auto;
        margin-bottom: auto
    }

    .notifications-total {
        color: get-color('white');
        background-color: get-color('err');
        margin-left: 10px;
        border-radius: 500px;
        font-size: 12px;
        width: 6%;
        height: 5%;
        text-align: center;
        line-height: 19px;
    }
}
