@import 'styles/index';

.fatal-error-container {
  color: get-color('white');
  font-family: $proxima;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: 100%;
  left: 0;
  line-height: 22px;
  position: fixed;
  top: 0;
  width: 100%;
  will-change: opacity;
  z-index: 100;
  text-align: center;
  background-color: rgba(0, 0, 0, 1);

  .fatal-error-content {
    position: fixed; /* or absolute */
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);

    p {
      line-height: 25px;
    }

    .primary-button {
        margin-top: 22px;
    }

    @media screen and (max-width:764px) {
        width: 70%;

        p {
            line-height: 20px;
        }
    }
  }
}
