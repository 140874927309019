@import "styles/index.scss";
.mi-card {
    border: 1px solid get-color(mercury);
    border-radius: $global-border-radius;
    font-family: $proxima;

    &.container {
        background-color: get-color('white');
        padding:1.25rem;
    }
    &.alert {
        border-color: get-color('err');
        background-color: rgba(get-color('err'), 0.1);
        padding:1rem;
        margin:20px 20px 0;
    }
    .mi-card-content {
        margin-left: 15px;

        .content-header {
            font-weight: 600;
        }

        .content-text {
            color: get-color(dusty-grey);
        }

        .content-link {
            margin-top: 25px;
            color: get-color(steel-blue);

            .icon {
                margin-left: 15px;
            }
        }
    }
    .content-author {
        display: flex;
        border-top: 1px solid get-color(mercury);
        margin-right: 15px;
    }

    .content-author-image {
        width: 40px;
        height: 40px;
        margin-top: 20px;
        border-radius: 6px;
    }

    .content-author-name {
        margin-top: 30px;
        margin-left: 15px;
        color: get-color(dusty-grey);
    }

    &.primary {
        max-width: 298px;

        .mi-card-image {
            width: 100%;
            height: 133px;
            border-radius: 16px 16px 0px 0px;
        }
    }

    &.article {
        max-width: 300px;

        .mi-card-image {
            width: 300px;
            height: 133px;
            border-radius: 16px 16px 0px 0px;
        }
    }

    &.secondary {
        width: 600px;
        min-height: 121px;
        display: flex;

        .mi-card-image {
            max-width: 188px;
            max-height: auto;
            border-radius: 16px 0px 0px 16px;
        }
    }

    &.tertiary{
        max-width: 310px;
        border: none;

        .mi-card-content {
            margin-left: 0px;
        }

        .mi-card-image {
            width: 310px;
            height: 200px;
            border-radius: 16px 16px 0px 0px;
        }
    }
}

@include media-query(764) {
    .mi-card {

        &.alert {

            margin:0 0 20px;
        }
    }
}
