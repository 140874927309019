@import 'styles/index';
.personal-task-form {
    background: get-color(wild-sand);
    padding: 20px;

    input:focus::placeholder { color: transparent; }
    textarea:focus::placeholder { color: transparent; }
    input:focus::-moz-placeholder { color:transparent; }
    textarea:focus::-moz-placeholder { color:transparent; }
    input:focus:-ms-input-placeholder { color:transparent; }
    textarea:focus:-ms-input-placeholder { color:transparent; }


    .field-wrap {
        margin: 15px 0px;

        .input {
            width: 100%;
            padding: 5px;
            min-width: 90%;

            &.input--error {
                border: 1px solid get-color('err');
                border-radius: 3px;
            }
        }
    }

    .label {
        font-size: 12px;;
    }

    .personal-task-icon-button {
        @include nakedbutton;
        padding: 0;
    }

    .personal-tasks-date-picker-container {
        position: relative;
        margin-bottom: 20px

        ;

        .label--dueDate {
            margin-left: 1px;
        }
        .personal-task-form-date-picker {
            width: 50%;
            width: calc(100% - 45px);
            margin: 5px 0px;
            position: relative;


            input {
                width: 100%;
                border: 1px solid #c4c4c4;
                border-radius: 6px;
                min-height: 21px;
                padding: 5px;
                min-width: 90%;
            }

            input[type="date"]::-webkit-calendar-picker-indicator {
                background: transparent;
                bottom: 0;
                color: transparent;
                cursor: pointer;
                height: auto;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
            }

            .react-datepicker__input-container {
                input {
                    width: 100%;
                    padding: 5px;
                }
            }
        }

        .personal-task-calendar-icon {
            position: absolute;
            right: 45%;
            top: 30px;
        }
    }

    .react-datepicker__tab-loop {
        .react-datepicker-popper {
            z-index: 100;

            // there is a current bug with datepicker that shows the selected day in previous months, this is a temporary fix for this
            .react-datepicker__day--keyboard-selected {
                background-color: transparent;
                color: get-color('black');

                &:focus {
                  box-shadow: unset;
                }
            }
        }
    }

    .task-description-container {
        position: relative;

        .task-description {
            padding: 5px 5px;
            margin-bottom: 20px;
            resize: none;
        }

        .task-description-length-count {
            color: get-color('body-text');
            position: absolute;
            right: 0;
            bottom: 5px;
            font-size: .875rem;
            margin:0;
        }
    }

    .task-form-buttons {
        display: flex;
        justify-content: flex-end;

        .button {
            width: 30%;
            &.text-only-button {
                width: 20%;
                margin-top: 10px;
                text-align: center;
            }
        }
    }

    @media (max-width: 764px) {
        .personal-tasks-date-picker-container {
            .react-datepicker-wrapper.personal-task-form-date-picker {
                width: 100%;
            }

            .personal-task-calendar-icon {
                position: absolute;
                right: 15px;
                top: 23px;
            }
        }

        .task-form-buttons {
            .button {
                width: 50%;
                &.text-only-button {
                    background: get-color('white');
                    margin-top: 10px;
                    margin-right: 3%;
                    width: 50%;
                    border: 1px solid get-color('neutral-dark');
                    border-radius: 3px;
                }
            }
        }
    }
}

@include media-query(764) {
    .personal-task-form .personal-tasks-date-picker-container .personal-task-form-date-picker {
        width:50%;
    }
}
