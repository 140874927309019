@import 'styles/index';

.sortable-table {
    font-family: $proxima;
    color:get-color('black');
    font-size: .75rem;
    margin-bottom: 25px;
    text-align: left;

    .primary-button {
      flex: 0 0 auto;
      width: auto;
      padding-right: 20px;
      padding-left: 20px;
      margin-right: auto;
      margin-left: auto;

    }

    .text-only-button.processing {
      font-size: 14px;
    }

    &--with-alternate-mobile-view {
      display: none;
    }

    a {
      font-weight: bold;
      color:get-color('aqua');
      font-size: 1rem;
      text-decoration: none;
      &:hover,&:active {
        color:get-color('aqua');
        text-decoration: underline;
      }
    }
    &__header {
      color: get-color('white');
      font-size: .75rem;
      text-align: center;


    }
    &__cell {
      padding: 7px 6px;
      &--in-even-row {
        background: get-color('portal-bg-color');
      }

      &--large {
        padding:16px 3px;
        font-size: 1rem;
      }

      &--small {
        font-size: .75rem;
        font-weight: bold !important;
      }
      &--header {
        background: get-color('indigo');
        padding:16px 3px;
        font-size: 1rem;
        &-small {
          font-size: .75rem !important;
          font-weight: bold !important;

          button {font-weight: bold;}
        }
      }
      &--left {
        text-align: left !important;
        padding: 15px 10px !important;
      }
      &--no-pad {
        padding:0 !important;
      }
      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
    &__sort-button {
      color: get-color('white');
      @include nakedbutton;
      font-size: 1rem ;
      padding-right: 15px;
      padding: 0 15px 0 0;
      position: relative;

      &::before,
      &::after {
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-top-color: get-color('white');
        border-width: 5px;
        right:0;
      }
      &::before {
        top: 54%;
        transform: scale(.9, 1);

      }

      &::after {
          top: 38%;

          transform: scale(.8, 1) translateY(-80%) rotate(180deg) ;
      }

      &--active {
        &.sortable-table__sort-button--asc {
          &::before {
            opacity: .5;
          }
        }
        &.sortable-table__sort-button--desc {
          &::after {
            opacity: .5;
          }
        }
      }
    }
  }



  @include media-query(764) {

    .sortable-table {
      display: table;
      &__cell {
        padding:16px 6px;
      }
      &__header {
        text-align: left;
      }
      .text-only-button.processing {
        font-size: 16px;
      }
    }
  }

