@import 'styles/index';

.checklist-dropdown-card {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  padding: 0;
  padding-bottom: 10px;
  width: 100%;
  position: relative;


  &:focus,
  &:active {
    border: 0;
    outline-color: get-color(loblolly);
  }

  &:hover {
    background-color: rgba(243, 245, 247, 0.5);
  }

  .checkbox--task {
    .icon {
      right: -3px;
      top: 0;
    }
  }

  .icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateX(5px);
    transform: translate(5px, -10px);

  }


  .complete-input-container {
    padding-left: 20px;
    width: 15%;

    .field-wrap {
      margin-top: 8px;
    }
  }

  .checklist-dropdown-card-content {
    padding-top: 10px;
    text-align: left;
    width: 75%;


    p {
      font-weight: 600;
      margin-bottom: 0;
    }

    .checklist-card-phase-text {
      color: rgba(0, 0, 0, 0.25);
      margin-top: 5px;

      &.overdue {
        color: get-color(err);
      }

      &.duetoday {
        color: get-color(err);
      }

      &.duesoon {
        color: get-color(tangerine);
      }
    }
  }

}
