@import '../../styles/index';

.missing {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - #{$mobile-top-nav-height});
  padding: 20px;
  text-align: center;
  padding-bottom: $mobile-top-nav-height !important;

  h1 {
    font-size: 2.5rem;
    margin: 10px 0;
  }

  p {
    font-size: 1.25rem;
    margin: 10px 20px;
  }

  a {
    background-color: get-color('aqua');
    border-radius: 3px;
    color: get-color('white');
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1;
    padding: 14px 20px;

    &:hover,
    &:focus,
    &:active {
      background-color: get-color('indigo');
      color: get-color('white');
      text-decoration: none;
    }
  }

  &__icon {
    height: 71px;
    width: 71px;
  }
}

@include media-query(764) {
  .missing {
    flex-direction: row;
    min-height: calc(100vh - 120px);
    padding-bottom: 30px !important;

    &__icon {
      height: 137px;
      width: 137px;
    }

    &__content {
      padding-left: 20px;
      text-align: left;
    }

    p {
      margin-left: 0;
    }

    h1 {
      margin-top: 0;
    }

    a {
      margin-bottom: 0;
    }
  }
}
