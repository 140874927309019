$colors: (
  casper: #A7C6D0,
  'indigo': #3b4559,
  'white': #fff,
  'slate': #a6bbc8,
  'black': #000,
  'tangerine': #f1b300,
  'aqua': #4298b5,
  'turquoise': #005a70,
  'neutral-light': #ebf1f5,
  'neutral-medium': #dde4e8,
  'neutral-dark': #c2c8cc,
  'body-text': #969696,
  'portal-bg-color': #f3f5f7,
  'dark-aqua': #3b7f96,
  'confirmation': #55b589,
  'progress': #fed537,
  'err': #ec7373,
  'gray': #8c8c8c,
  'silver': #c4c4c4,
  'light-grey': #f7f7f7,
  surfie-green: #11657D,
  alto: #d4d4d4,
  tundora:#474747,
  cornflower-blue:#5f84ff,
  casal: #27596a,
  chambray: #3e5996,
  boston-blue: #4298b5,
  silver-chalice:#a4a4a4,
  shuttle-gray:#586171,
  cyprus: #003b49,
  mercury: #e4e4e4,
  concrete: #f2f2f2,
  mine-shaft: #2d2d2d,
  mystic: #dde4e9,
  portage: #9686f5,
  geraldine: #f5a186,
  persian-pink: #f586ae,
  oxford-blue: #3b4559,
  slate-grey: #7d97ac,
  dusty-grey: #969696,
  steel-blue: #4698b3,
  link-water: #e5eafa,
  athens-gray:#f2f5f7,
  apricot: #ec7373,
  loblolly:#c2c8cc,
  bright-sun:#fed537,
  geyser: #c8d3da,
  thunder: #231f20,
  cinnabar: #E2483E,
  wild-sand: #F5F5F5,
);
