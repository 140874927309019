@import 'styles/index';
$border-radius: 6px;

[role='tabpanel'] {
  &[aria-hidden] {
    display: none;
  }
}

.status-indicator {
  background-color: get-color(neutral-medium);
  border: 2px solid darken(get-color(neutral-medium), 10);
  border-radius: 50%;
  display: inline-block;
  flex: 0 0 0.75rem;
  height: 0.75rem;
  margin-right: 10px;
  text-indent: -999em;
  width: 0.75rem;
  min-height: 12px !important;

  &--started {
    background-color: get-color(progress);
    border-color: darken(get-color(progress), 15);
  }

  &--complete {
    background-color: get-color(confirmation);
    border-color: darken(get-color(confirmation), 10);
  }

  &--plain {
    background-color: transparent;
    border: 0;
  }

}

.tab-content__simple-html {
  @include html-styles;
  line-height: (19/16);

  &:empty {
    display: none;
  }
}

.tabs {
  &__content {
    background-color: get-color('white');
    color: get-color(dusty-grey);
    font-family: $proxima;
    font-size: 1rem;

    line-height: 1.1875rem;
    max-width: 100vw;
    min-height: calc(100vh - 85px);
    overflow: hidden;
    padding: 60px 0 0;
    position: relative;
    z-index: 1;

    .progress-accordion-container:nth-child(even) {
      .card {
        background-color: get-color('portal-bg-color');
      }
    }
  }

  &__nav {
    background-clip: padding-box;
    border: 1px solid get-color('neutral-medium');
    border-radius: $border-radius;
    display: none;

    &-list {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  &__mobile-nav {
    display: flex;
    height: 60px;
    left: 0;
    position: absolute;
    align-items: center;
    top: 0;
    width: 100%;
    z-index: 2;

    > p {
      color: get-color('indigo');
      font-size:20px;
      text-align: center;
      margin: 0;
      flex: 0 0 100%;

    }
  }
}



.tab {
  flex: 1 1 auto;
  font-family: $proxima;
  position: relative;
  z-index: 1;

  &:hover,
  &[aria-selected] {
    z-index: 2;
  }

  &__button {
    background-clip: padding-box;


    &:focus {
      box-shadow: 0 0 0 1px get-color(neutral-dark);
      outline: none;
    }
  }


  &::after {
    background-color: get-color('neutral-medium');
    content: ' ';
    height: 32px;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
  }

  &:first-child {
    &::after {
      display: none;
    }
  }

  &[aria-selected='true'] {
    & + .tab::after,
    &::after {
      display: none;
    }
  }

  &__status {
    font-family: $proxima;
    text-transform: uppercase;
    font-size: 10px;
    padding: 3px 7px;
    border-radius: 10px;
    max-height: 20px;
    background-color: get-color('white');
    color: rgba(0, 0, 0, .25);
    &--complete {
      color: get-color('confirmation');
    }
    &--started {
      color: get-color('tangerine');
    }
  }



  &__label {
    flex: 0 0 auto;
    font-size: 0.875rem;
    font-weight: normal;
    color: get-color('black');
    pointer-events: none;
  }

  &__button {
    @include nakedbutton;
    display: flex;
    overflow: hidden;
    padding: 8px 0;
    width: 100%;
    border-top: 4px solid get-color(loblolly);
    border-bottom:1px solid get-color('neutral-medium');
    flex-wrap: wrap;

    justify-content: center;

    &--first {
      border-bottom-left-radius: 6px;
      border-left: 1px solid get-color('neutral-medium');;
    }
    &--last {
      border-bottom-right-radius: 6px;
      border-right: 1px solid get-color('neutral-medium');;
    }

    &--complete {
      border-top-color: get-color('confirmation');
    }
    &--started {
      border-top-color: get-color('tangerine');

    }

    &--active {
      background-color: get-color('neutral-light');
    }

    &:hover,&:focus,&:active {
      color: rgba(0, 0, 0, .25);
      .tab__label {
        color: get-color('black');
      }
    }
  }
}

.tab-content {
  &[aria-hidden='true'] {
    display: none;
  }

  &__simple-html {
    padding: 15px 20px 0;

    & + .tab-content__simple-html {
      padding-top: 20px;
    }
  }

}

.desktop-right-column,
.desktop-left-column {
  flex: 0 0 100%;
  width: 100%;
}

.desktop-right-column {
  padding: 0 20px 20px;

}

.desktop-left-column {
  margin-top: 20px;

  &--preconstruction,
  &--construction {
    margin-top: 0;
  }
}

.tab-content__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.desktop-right-column--warranty {
  display: none;
}

.desktop-right-column--closing,
.desktop-right-column--design,
.desktop-right-column--financing,
.desktop-right-column--construction,
.desktop-right-column--preconstruction {
  order: 2;
}

@media screen and (min-width:765px) {
  .simple-fade-appear {
    opacity: 0;
  }


  .simple-fade-appear-active {
    opacity: 1;

    transition: opacity 350ms ease-out;
  }

  .simple-fade-appear-done {
    opacity: 1;
  }
}


@media screen and (min-width:764px) {
  .tab-content__wrapper {
    display: flex;
    flex-wrap: nowrap;
  }

  .tabs__content {
    min-height: 0;
    padding: 20px 0 0;
  }



  .desktop-right-column {
    flex: 0 0 40%;
    padding: 0 0 20px 20px !important;
    width: 40%;
  }

  .desktop-right-column--closing,
  .desktop-right-column--warranty,
  .desktop-right-column--design,
  .desktop-right-column--financing {
    display: block;
  }

  .desktop-left-column {
    flex: 0 0 60%;
    margin-top: 0;
    order: 1;
    padding-right: 0;

    width: 60%;
  }


  .desktop-right-column {
    order: 2;;
  }

  .tab-content {
    &__simple-html {
      padding: 0;
    }
  }

  .tab__label {
    font-size: 0.75rem;
  }

  .tabs__mobile-nav {
    display: none;
  }

  .tabs__nav {
    display: block;
    border: 0;
    border-radius: 0;
    overflow: hidden;

  }
}

@include media-query(980) {
  .tab__label {
    font-size: 1rem;
  }
}

@include media-query(1025) {
  .desktop-right-column {
    flex: 0 0 35%;

    width: 35%;
  }

  .desktop-left-column {
    flex: 0 0 65%;

    width: 65%;
  }
}
