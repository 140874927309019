@import 'styles/index';

.error {
  background: get-color('err');
  border-radius: 0.1875rem;
  color: get-color('white');
  display: inline-block;
  font-family: proxima-nova,Arial,sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.25em;
  overflow: hidden;
  padding: 1.25rem;
  position: relative;
  text-align: left;
  transition: opacity 0.4s cubic-bezier(0.14, 0.58, 0.18, 1) 0s;
  vertical-align: top;
  width: 100%;
  z-index: 2;

  &--margin-bottom {
    margin-bottom: 30px;
  }

  &--below {
    padding:.75rem;
    margin-top: 5px;
  }

  a {
    color: get-color('white');
    text-decoration: underline;
    &:hover,&:active,&:focus {
      color: get-color('tangerine');
    }
  }

  &--field {
    background: inherit;
    color: get-color(apricot);
    font-size: 1rem;
    font-weight: 500;
    padding: 10px 0;
  }

  &--center {
    text-align: center;
  }

  &--margin-around-mobile {
    margin: 20px;
    max-width: calc(100% - 40px);
  }
}
@include media-query(764) {
  .error--margin-around-mobile {
    margin: 20px 0;
    max-width: 100%;
  }
}
