@import 'styles/index';

.form {
  font-family: $proxima;

  &__bottom-links {
    padding: 25px 0 0;
    text-align: left;
  }

  &--busy {
    opacity: 0.2;
    pointer-events: none;
    position: relative;
  }
}

.login-form {
  background: get-color('white');
  border: 1px solid get-color('mercury');
  border-radius: 6px;

  margin: 0 auto;
  padding: 2.4rem 0; // 3.625rem;

  &__section {
    padding: 30px;
  }

  &__message {
    background-color: get-color('portal-bg-color');
    padding:10px;
    color:get-color('indigo');
    font-size: .875rem;
    font-family: $proxima;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.form-bottom-links {
  margin-top: 30px;

  div {
    margin-top: 2px;
    margin-bottom: 2px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.block-button {
  border-radius: 6px;
  color: get-color('white');
  display: block;
  font-weight: 300;
  margin-top: 10px;
  padding: 14px 7px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.block-button--white {
  background: get-color('white');
  color: get-color('aqua');
  font-weight: bold;
  margin: 0;
  padding: 14px;
}

@media screen and (min-width: 37.5rem) {
  .login-form {
    &__section {
      padding: calc(1.25rem - 10px) 4.1rem 1.25rem;
    }
    .heading--h1 {
      margin-bottom: 30px;
    }
  }
}
