@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import '~bootstrap/dist/css/bootstrap.css';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  custom-tablet: 764px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

@import '~bootstrap/scss/bootstrap-grid';

html {
  scroll-behavior: smooth;
}

iframe {
  pointer-events: none;
}

* {
  outline-color:#c2c8cc;
}

@import '../styles/index';

@each $name, $color in $colors {
  .#{$name} {
      color: $color;
  }

  .bg-#{$name} {
      background-color: $color;
  }
  .border-#{$name} {
      border-color: $color;
  }
}

/* add css module styles here (optional) */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
html {
  height:100%;
}

body {
  height: -webkit-fill-available;
  margin: 0;
  padding: 0;
  background-color: #3b4559;
  color: get-color(tundora);
  font-family: 'proxima-nova', Arial, sans-serif;
}

html {
  height: -webkit-fill-available;
}


.generic-container {
  background-color: get-color('white');
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: 0;

  &--blue-bg {
    background-color: get-color('portal-bg-color');
  }
  &--bg-neutral-light {
    background-color: get-color(neutral-light);
  }
}
.desktop-only {
  display: none;
}
.page-wrapper {
  background-color: white;
  padding-bottom: 20px;
  &__intro-text {
    margin:20px;
  }
}
@include media-query(764) {
  .desktop-only {
    display: inline;
  }
  .generic-container {
    border: 1px solid get-color('neutral-medium');
    border-radius: 6px;
    padding: 30px 50px;
    margin: 30px 20px;

    &--blue-bg {
      background-color: get-color('white');
    }
    &--bg-neutral-light {
      background-color: get-color(neutral-light);
    }
  }
  .page-wrapper {
    border: 1px solid get-color(geyser);
    margin: 20px;
    padding: 50px 60px;
    border-radius: $global-border-radius;
    &--flex {
      display: flex;
      flex-wrap: wrap;
    }

    &__intro-text {
      margin: 20px 0;
    }
  }
}

@media screen and (max-width:767px) {
  .login-form,
  .lot-association-details-container,
  .body--supports-touch {
    .input--email,
    .input--number,
    .input--password,
    .input--date,
    .input--text {
      font-size: 1rem;
    }
  }
}


.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-left: 20px;
  padding-right: 20px;
}
.row {
  margin-right: -20px;
  margin-left: -20px;
}
.container-lg {
  padding-left: 20px;
  padding-right: 20px;
}

.visuallyhidden,
.visuallyhidden--mobile {
  left: -999em;
  position: absolute;
  top: -999em;
}

.justify-content-space-between {
  justify-content: space-between;
}

.col--no-pad-mobile {
  padding-left: 0;
  padding-right: 0;
}

.justify-content-space-around--desktop,
.justify-content-space-around--mobile,
.justify-content-space-around {
  justify-content: space-around;
}

.col--no-pad {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 764px) {
  .generic-container--extra-side-padding-desktop {
    padding-left: 60px;
    padding-right: 60px;
  }
  .generic-container--extra-top-padding-desktop {
    padding-top: 40px;

  }
  .justify-content-left--desktop {
    justify-content: flex-start;
  }
  .col--no-pad-desktop,
  .col--no-pad {
    padding-left: 0;
    padding-right: 0;
  }
  .col--no-pad-mobile {
    padding-left: 20px;
    padding-right: 20px;
  }

  .col--pad-left-desktop {
    padding-left: 20px;
    padding-right: 0;
  }

  .visuallyhidden--mobile {
    left: auto;
    position: relative;
    top: auto;
  }

}
@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 982px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 982px;
  }
}
