@import 'styles/index';

.lot-list {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;


  address {
    font-style: normal;
    pointer-events: none;
  }

  &-item {
    border: 1px solid get-color('neutral-medium');
    border-radius: 3px;
    display: flex;
    list-style: none;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;

    &--selected {
      background-color: get-color('indigo');
      border: 1px solid get-color('neutral-medium');
      border-radius: 3px;
      color: get-color('white');
      overflow: hidden;
    }

    &--single {
      background-color: get-color('indigo');
      border: 1px solid get-color('indigo');
      color: get-color('white');

      display: flex;
      flex-wrap: wrap;
      padding: 18px;
    }

    .input-wrap {
      flex: 0 1 40px;
      max-width: 40px;
      order: 2;
    }

    &__delete {
      @include nakedbutton;
      color: get-color('aqua');
      flex: 0 0 60px;
      height: 100%;
      left: 0;
      opacity: 0;
      padding: 0 0 0 18px;
      position: absolute;
      top: 0;
      transform: translateX(-60px);
      transition: transform 0.3s;
      width: 60px;
      will-change: transform;

      z-index: 1;

      &:active,
      &:hover,
      &:focus {
        color: get-color('indigo');
      }

      &--remove-mode {
        opacity: 1;
        transform: none;
      }

      &--is-confirmable {
        opacity: 0;
        transform: translateX(-60px);
      }

      &--active,
      &--selected {
        color: get-color('white');

        &:active,
        &:hover,
        &:focus {
          color: get-color('aqua');
        }
      }

      &-inner {
        display: block;
        height: 100%;
        position: relative;
        text-indent: -999em;
        width: 100%;
      }

      svg,
      .icon {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__address {
      flex: 1 1 calc(100% - 40px);
      font-style: normal;
      margin: 0;
      order: 1;
      pointer-events: none;
    }

    &__lot-id {
      flex: 0 0 100%;
      margin: 0;
      order: 3;
    }

    &__confirm-deletion {
      @include nakedbutton;
      align-items: center;
      background-color: get-color('err');
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
      color: get-color('white');
      display: flex;
      height: 100%;
      padding: 0 20px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      transform: translateX(100%);
      transition: transform 0.3s;
      will-change: transform;
      z-index: 3;

      &--active {
        transform: none;
      }
    }

    &__label {
      align-items: center;
      background-color: get-color('white');
      border-radius: 3px;
      color: get-color('silver');
      cursor: pointer;
      display: flex;
      flex: 0 0 100%;
      flex-wrap: wrap;
      margin: 0;
      padding: 18px;
      position: relative;
      transition: transform 0.3s;
      will-change: transform;
      z-index: 2;

      &--remove-mode {
        transform: translateX(60px);
      }

      &--for-deletion {
        transform: translateX(-86px);

        input,
        .input-wrap {
          opacity: 0;
          pointer-events: none;
        }
      }





      &:hover,
      &:focus,
      &:active {
        background-color: get-color(neutral-light);
        outline: none;
      }

      &--selected {
        background-color: transparent;
        color: get-color('white');
      }

      &--in-remove-mode,
      &--remove-mode {
        pointer-events: none;
      }

      &--selected,
      &--remove-mode {
        &:hover,
        &:focus,
        &:active {
          background-color: transparent;

        }
      }
    }

  }
}


.lot-list-item--selected {
  .checkbox__indicator {
    display: block;
  }
}
