@import "../../../styles/index";

$backgroundColor: get-color('link-water');
$borderColor:get-color('cornflower-blue');
$primaryTextColor: get-color('black');
$secondaryTextColor: get-color('oxford-blue');
$skeletonBackgroundColor: get-color('white');
$skeletonBorderColor: get-color('neutral-medium');

section.messageboard-container {
    position: relative;
    border-radius: 6px;
    padding: 20px;
    margin:20px 20px 0;

    background-color: $backgroundColor;
    border: 1px solid $borderColor;

    h1 {
        margin-bottom: 0;
        color: $primaryTextColor;
    }
    span {
        color: $secondaryTextColor;
    }

    &.skeleton {
        background-color: $skeletonBackgroundColor;
        border: 1px solid $skeletonBorderColor;
    }

}

@include media-query(764) {
    section.messageboard-container {
        margin-right: 0;;
        margin-left: 0;
        margin-bottom: 20px;
    }
}
