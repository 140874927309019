@import 'styles/index';

.link-box {
  background: get-color('indigo');
  border-radius: $global-border-radius;
  color: get-color('white');
  font-size: 1rem;
  font-weight: 600;
  line-height: (19/16);
  margin: 0 20px 20px;
  overflow: hidden;

  &__body {
    color: get-color('white');
    font-weight: normal;
  }

  &__content {
    padding: 30px;
    position: relative;
  }

  &__body,
  &__title,
  &__links {
    position: relative;
    z-index: 2;
  }

  &__title {
    color: get-color('white');
    font-size: 1.5rem;
    font-weight: normal;
  }

  &__text {
    margin: auto;
  }

  &__link {
    background: get-color(boston-blue);
    border: 1px solid transparent;
    border-radius: 3px;
    color: get-color('white');
    display: flex;
    font-weight: 600;
    margin-top: 10px;
    padding: 14px 20px;
    text-align: center;
    transition: background 0.2s;
    width: 100%;

    &:hover,
    &:focus,
    &:active {
      background: get-color(cyprus);
      color: get-color('white');
      text-decoration: none;
    }

    &--secondary {
      background: get-color('white');
      border: 1px solid get-color(loblolly);
      color: get-color(boston-blue);

      &:hover,
      &:focus,
      &:active {
        background: get-color(neutral-medium);
        color: get-color(boston-blue);

      }
    }
  }

  &__bg {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

}

@include media-query(572) {
  .link-box {
    margin: 0 20px 30px;

    &__links {
      display: flex;
      justify-content: space-between;
      &--stack {
        flex-wrap: wrap;
      }
    }

    &__link {
      flex: 1 1 auto;
      max-width: calc(50% - 5px);

      &--stack {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

@include media-query(764) {
  .link-box {
    margin-left: 0;
    margin-right: 0;

    &__links {
      display: block;
      &--stack {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &__link {

      max-width: none;
    }
  }
}

@include media-query(980) {
  .link-box {
    &__links {
      display: flex;
      &--stack {
        flex-wrap: wrap;
      }

    }

    &__link {

      max-width: calc(50% - 5px);

      &--stack {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
