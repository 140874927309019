
@mixin html-styles() {
  iframe {
    max-width: 100%;
  }

  blockquote {
    background-color: rgba(66, 152, 181, 0.1);
    border-left: get-color('aqua') 2px solid;
    color: get-color(dusty-grey);
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 600;
    line-height: 17px;

    line-height: (15/12);
    margin: 30px 0;
    padding: 20px 25px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: get-color('indigo');
    }

    h1,
    h2,
    h3 {
      color: get-color('indigo');
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 10px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      line-height: (15/12);

      &:last-child {
        margin-bottom: 0;;
      }
    }

    h4,
    h5,
    h6 {
      font-size: 0.875rem;
      font-style: italic;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  p {
    background-color: transparent !important;
  }

  table {
    font-size: 0.75rem;
    font-weight: 600;
    max-width: 100%;
    width: 100%;;
  }

  tbody tr {
    &:nth-child(even) {
      background-color: get-color('portal-bg-color');
    }
  }

  th,
  td {
    padding: 12px;

    &:first-child {
      border-bottom-left-radius: $global-border-radius;
      border-top-left-radius: $global-border-radius;
    }

    &:last-child {
      border-bottom-right-radius: $global-border-radius;
      border-top-right-radius: $global-border-radius;
    }
  }

  td {
    color: get-color('black');
  }

  th {
    background-color: get-color('indigo');
    color: get-color('white');


  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
 color: get-color(tundora);
  }

  h1 {

    font-size: 2.5rem;
    font-weight: 600;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 400;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
  }

  h4 {
    font-size: 1rem;
    font-weight: 500;
  }

  h5 {
    font-size: 0.875rem;
    font-weight: 600;;
  }

  h6 {
    font-size: 0.75rem;
    font-weight: 600;
  }

  li {
    margin: 8px 0;
  }

  ol,
  ul {
    margin-bottom: 30px;
  }



  strong {
    font-weight: 600;
  }

  img {
    display: block;
    height: auto;
    width: 100%;
    border-radius: 3px;
    &.img-90 {
      width: 90%;
    }

    &.img-80 {
      width: 80%;
    }

    &.img-70 {
      width: 70%;
    }

    &.img-60 {
      width: 60%;
    }

    &.img-50 {
      width: 50%;
    }
  }

  a {
    color: get-color('aqua');
    text-decoration: underline;

    &:visited {
      color: get-color('aqua');
    }

    &:focus,
    &:active,
    &:hover {
      color: get-color('dark-aqua');
      text-decoration: none;
    }
  }
}
