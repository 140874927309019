@import "styles/index.scss";
.mi-card-badge {

    display: inline-block;
    padding: 8px 15px 8px 15px;
    color: get-color("white");
    position: absolute;
    left: 24px;
    top: 24px;
    font-family: $proxima;

    &.buying {
        background-color: get-color(portage);
    }

    &.design {
        background-color: get-color(geraldine);
    }

    &.maintenance {
        background-color: get-color(persian-pink);
    }
}
