@import 'styles/index';

.button {
  align-items: center;
  border-radius: 3px;
  border: 0;
  display: flex;
  font-family: $proxima;
  font-size: 1rem;
  font-weight: 300;
  height: 42px;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &--dont-grow {
    flex: 0 0 auto;
    width: auto;
  }

  &:only-child {
    margin-top: 0;
  }

  &__text {
    &--bold {
      font-weight: bold;
    }
  }
}

.play-button {
  background: transparent;
  border: 0;
  cursor: pointer;
  overflow: hidden;
  padding: 0;
  position: relative;

  &::after {
    content: ' ';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: box-shadow 0.2s ease-in-out;
    width: 100%;
    z-index: 2;
  }

  i {
    transition: background 0.2s ease-in-out;
  }

  img {
    position: relative;
    z-index: 1;
    transition: transform .2s ease-in-out;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    &::after {
      box-shadow: inset 0 0 2px 2px rgba(255, 255, 255, 1);
    }

    img {
      transform: scale(1.05);
    }

    i {
      background: rgba(255, 255, 255, 0.4);

    }
  }
}

.primary-icon-only-button,
.secondary-icon-only-button {
  border-radius: 20px;
  padding: 10px;
}

.primary-button,
.icon-primary-button,
.primary-icon-only-button {
  background: get-color('aqua');
  border: 1px solid get-color('aqua');
  color: get-color('white');

  &:hover,
  &:active {
    background: get-color('dark-aqua');
    border: 1px solid get-color('dark-aqua');
    cursor: pointer;
    .button__text {
      color: get-color('white');
    }
  }

  &:focus {
    border: 1px solid get-color('neutral-dark');
    outline: 0;
  }

  &.disabled {
    background: get-color('neutral-medium');
    border: 0;
    pointer-events: none;
  }
}

.secondary-button,
.icon-secondary-button,
.secondary-icon-only-button {
  background: get-color('white');
  border: 1px solid get-color('neutral-dark');
  color: get-color('aqua');




  &:hover,
  &:active {
    background: get-color('neutral-light');
    cursor: pointer;
  }

  &:focus {
    background: get-color('white');
  }

  &.disabled {
    background: get-color('white');
    border: 1px solid get-color('neutral-medium');
    color: get-color('neutral-medium');
    pointer-events: none;
  }

  &--flip {
    background: get-color('portal-bg-color');
    &:hover,
    &:active {
      background: get-color('neutral-light');

    }

  }
}

.button--with-icon {
  .icon {
    padding-right: 10px;
  }
}

.link {
  align-items: center;
  color: get-color('aqua');
  display: flex;
  font-family: $proxima;
  font-size: 1rem;
  font-weight: 300;
  justify-content: center;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:hover,
  &:active {
    color: get-color('dark-aqua');
    cursor: pointer;
  }

  &:focus {
    border: 1px solid get-color('neutral-dark');
  }

  &.disabled {
    color: get-color('neutral-medium');
    pointer-events: none;
  }


}

.text-only-button,
.text-only-link {
  background: transparent;
  border: 0;
  color: get-color('aqua');
  display: inline;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  text-align: left;
  width: auto;

  &--bold {
    font-weight: bold;
  }

  &:focus {
    outline-color: get-color(loblolly);
  }
}

.text-only-button{
  &.disabled {
    color: get-color('gray');
  }
}

.text-only-button:hover {
  text-decoration: underline;
}

.secondary-button:hover {
  text-decoration: underline;
  color:#4298b5 !important
}

@include media-query(764) {
  .secondary-button {
    &--flip {
      background: get-color('white');
      padding-right: 20px;
      padding-left: 20px;
      &:hover,
      &:active {
        background: get-color('neutral-light');
      }
    }
  }
}
