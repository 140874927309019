@mixin nakedbutton($messWithBg: true) {
    @if $messWithBg == true {
      background: transparent;
    }
    border:none;

    &:focus,&:active {
      outline-color: get-color(tundora);
    }
  }
